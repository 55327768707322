import { isString } from "lodash";

export function fixNodeEmptyContent(element: Element) {
    if (isString(element.textContent) && element.textContent.trim().length === 0) {
        element.innerHTML = '&nbsp;';
    }
    if (element.children.length > 0) {
        for (const child of element.children) {
            fixNodeEmptyContent(child);
        }
    }
}