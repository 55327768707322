import { Dispatch } from "redux";
import { IataAirline } from "../../CartMaterial/objects/iataAirline";
import { AirportsObj } from "../objects/airport";
import { StationsObj } from "../objects/station";
import { FlightGroup } from "../objects/flightGroup";
import { Params } from "../objects/params";
import { Flight } from "../objects/flight";

import CheckBeforeRequest from '../../Common/CheckBeforeRequest';
import CountAgePaxType from '../Functions/CountAgePaxType';
import Axios from "axios";

import {SetOptionsError, SetSeatsLoaded} from '../../../Actions/FlightSearch'

export default (
    flight: Flight,
    searched_group: FlightGroup,
    currency: string | null,
    trace_id: string | null,
    airlines: Array<IataAirline> | null,
    airports: AirportsObj | null,
    stations: StationsObj | null,
    end_date: string | null,
    dispatch: Dispatch,
    setSeatsLoad: any) => {
    let { pass_check, headers } = CheckBeforeRequest();
    let pax_obj = CountAgePaxType(searched_group.travelers, end_date);
    let params: Params = {
        pax_type: `ADT:${pax_obj.ADT},CNN:${pax_obj.CNN}`,
        currency: currency,
        trace_id: flight.trace_id,
        provider_id: flight.provider_id
    }
    if (flight.flight_key !== null){
        params.flight_key = flight.flight_key;
    } else{
        let all_outbound_segments: Array<string> = [];

        flight.outbounds.map((outbound_flight, outbound_index) => {
            let outbound_segment: Array<string> = [];
            outbound_flight.legs.map((leg, leg_index) => {
                if (airlines !== null){
                    let marketing_airline = airlines.find(item => item.id === leg.marketing_airline);
                    outbound_segment.push(
                        `${leg.segment_key},
                        ${(marketing_airline === undefined ? (leg.marketing_airline ? leg.marketing_airline.airline_code : '') : marketing_airline.code)},
                        ${leg.marketing_flight_number ? leg.marketing_flight_number : leg.flight_number},
                        ${leg.origin_station ? (stations && stations[leg.origin_station] ? stations[leg.origin_station].code : '') : 
                            (leg.origin_airport ? (airports && airports[leg.origin_airport] ? airports[leg.origin_airport].code : '') : leg.origin.airport_code)},
                        ${leg.destination_station ? (stations && stations[leg.destination_station] ? stations[leg.destination_station].code : '') :
                            (leg.destination_airport ? (airports && airports[leg.destination_airport] ? airports[leg.destination_airport].code : '') : leg.destination.airport_code)},
                        ${leg.departure_datetime_locale ? leg.departure_datetime_locale : leg.departure_time},
                        ${leg.arrival_datetime_locale ? leg.arrival_datetime_locale : leg.arrival_time},
                        ${outbound_index},
                        ${leg.booking_code},
                        ${leg.fare_basis},
                        ${leg.origin_station ? 'true' : 'false'}`
                    );
                }
            })
            let outbound_segment_str = outbound_segment.join('|');
            all_outbound_segments.push(outbound_segment_str);
        })
        params.outbound_segments = all_outbound_segments.join("|");
    }
    if (pass_check){
        delete headers.Authorization;
        Axios({
            method: "POST",
            url: `${API_HREF}client/${window.id_owner}/flights/seat_map/`,
            data: params,
            headers: headers
        }).then(function(response){
            console.log('response:', response);
            if (setSeatsLoad !== undefined) {
                setSeatsLoad(true);
            } else {
                dispatch(SetSeatsLoaded(true));
            }
            // dispatch(setSeatsBySegment(response.data.flight));
            dispatch({type: 'FLIGHT_SET_SEATS_BY_SEGMENT', payload: response.data.flight});
        }).catch(function(error){
            if (setSeatsLoad !== undefined) {
                setSeatsLoad(true);
            } else {
                dispatch(SetSeatsLoaded(true));
            }
            console.log('error:', error);
            if (error.responseJSON !== undefined) {
                dispatch(SetOptionsError(flight.id, error.response.data));
            }
        })
    }
}