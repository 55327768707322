import { flatten, intersection, isNumber } from "lodash";
import { differenceInCalendarDays } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { getDestinationName } from "../../../Itinerary/utils/getDestinationName";
import { createDestinationMapPageUrl } from "./createDestinationMapPageUrl";
import { findDestinationGuideDescription } from "./findDestinationGuideDescription";
import { createDestinationOnRoadPageUrl } from "./createDestinationOnRoadPageUrl";
import { getItineraryDayDifference } from "./getItineraryDifference";
import { transformFlightsToProgramDetailsItems } from "./transformFlightsToProgramDetailsItems";
import { transformPoiToProgramDetailsItems } from "./transformPoiToProgramDetailsItems";
import { transformCarsToProgramDetailsItems } from "./transformCarsToProgramDetailsItems";
import { transformAccommodationsToProgramDetailsItems } from "./transformAccommodationsToProgramDetailsItems";
import { transformTransfersToProgramDetailsItems } from "./transformTransfersToProgramDetailsItems";
import { transformTrainsToProgramDetailsItems } from "./transformTrainsToProgramDetailsItems";
import { transformCruisesToProgramDetailsItems } from "./transformCruisesToProgramDetailsItems";
import { transformFerriesToProgramDetailsItems } from "./transformFerriesToProgramDetailsItems";
import { getPoiPlace } from "./getPoiPlace";
import { getPoiName } from "./getPoiName";
import { htmlHasEmptyContent } from "./htmlHasEmptyContent";
import { createDomObjectsFromHtml } from "../MailVisualEditor/utils/dom";
import { CreatePageOptions } from "../objects/createPageOptions";
import { fixNodeEmptyContent } from "../MailVisualEditor/utils/fixNodeEmptyContent";
import { getPoiDescription } from "./getPoiDescription";
import { Template } from "../utils/handlebars/roadbook.handlebars";
import BigPin from "../../../../Img/noun-pin-2251142 15.png?roadbook";
import ActivityPin from "../../../../Img/noun-location-55443142 1.png?roadbook";
import PoiPin from "../../../../Img/noun-location-55443143 1.png?roadbook";
import AccommodationPin from "../../../../Img/noun-pin-2251142 14.png?roadbook";
import HardCodedContentManifest from "./html/manifest.json";

export async function createProgramDetailsContent(options: CreatePageOptions): Promise<Parameters<Template>[0]['contents'][0][] | null> {
    const quotationCode = JSON.parse(localStorage.getItem("config") ?? '{}').quotation_code;
    const itinerary = options.itinerary.filter((item) => {
        return item.step_type === 'STEP';
    });
    const guides = await findDestinationGuideDescription(
        itinerary.map((item) => {
            return item.destination?.id;
        }).filter((item): item is NonNullable<typeof item> => {
            return !!item;
        }),
        true,
        null
    );
    const usedContents: number[] = [];
    const programs = await Promise.all(
        itinerary.map(async (step, index, steps): Promise<Parameters<Template>[0]['contents'][number]> => {
            const prevStep = steps[index - 1];
            const nextStep = steps[index + 1];
            const destination = step.destination ?
                getDestinationName(options.locale, step.destination).split(',')[0] ?? '' :
                step.city_name?.split(',')[0] ?? '';
            const nextDestination = nextStep?.destination ?
                getDestinationName(options.locale, nextStep.destination).split(',')[0] ?? '' :
                nextStep?.city_name?.split(',')[0] ?? '';
            const nightsCount = window.moment.utc(step.end_date).startOf('day').diff(
                window.moment.utc(step.start_date).startOf('day'),
                'days'
            );
            const activities = transformPoiToProgramDetailsItems({
                cart: options.cart,
                destinationId: step.destination?.id ?? null,
                startDate: step.start_date,
                endDate: step.end_date,
                locale: options.locale,
                t: options.t
            });
            const hotels = options.cart.accommodations.filter((item) => {
                return !item.accommodation.is_optional &&
                    (
                        window.moment.utc(item.accommodation.start_date).isAfter(
                            window.moment.utc(step.start_date),
                            'day'
                        ) ||
                        window.moment.utc(item.accommodation.start_date).isSame(
                            window.moment.utc(step.start_date),
                            'day'
                        )
                    ) &&
                    (
                        window.moment.utc(item.accommodation.end_date).isBefore(
                            window.moment.utc(step.end_date),
                            'day'
                        ) ||
                        window.moment.utc(item.accommodation.end_date).isSame(
                            window.moment.utc(step.end_date),
                            'day'
                        )
                    );
            }).sort((a, b) => {
                const aStartDate = window.moment.utc(a.accommodation.start_date);
                const bStartDate = window.moment.utc(b.accommodation.start_date);
                return aStartDate.isBefore(bStartDate) ? -1 : 1;
            });
            const poiRoad = options.cart.pois.filter((item) => {
                return !item.poi.is_optional &&
                    nextStep &&
                    window.moment.utc(item.poi.start_date).isBetween(
                        window.moment.utc(step.end_date),
                        window.moment.utc(nextStep.start_date)
                    ) &&
                    window.moment.utc(item.poi.end_date).isBetween(
                        window.moment.utc(step.end_date),
                        window.moment.utc(nextStep.start_date)
                    );
            });
            const filteredActivities = activities.filter((item): item is typeof item & { latitude: string, longitude: string } => {
                return window.moment.utc(item.dateOrder).isBetween(
                    window.moment.utc(step.start_date),
                    window.moment.utc(step.end_date)
                ) &&
                    !!item.latitude &&
                    !!item.longitude;
            });
            const accommodations = transformAccommodationsToProgramDetailsItems({
                locale: options.locale,
                cart: options.cart,
                startDate: step.start_date,
                endDate: step.end_date,
                t: options.t
            });

            const daysDifference = getItineraryDayDifference(step);
            if (step.destination?.id === nextStep?.destination?.id) {
                daysDifference.pop();
            }
            const days = daysDifference.map((item) => {
                return differenceInCalendarDays(
                    item,
                    utcToZonedTime(itinerary[0]?.start_date ?? step.start_date, 'Etc/UTC')
                ) + 1;
            });

            const byDayContent = options.itineraryContent?.mode === 'by-day' ?
                options.itineraryContent.content.filter((item) => {
                    return intersection(item.day, days).length > 0 && !usedContents.includes(item.id);
                }) :
                null;
            const byStepContent = options.itineraryContent?.mode === 'by-step' ?
                options.itineraryContent.content[index] ?? null :
                null;
            const byStepContentLocalization = byStepContent?.localization.find((item) => {
                return item.locale === options.locale;
            });

            if (
                byDayContent &&
                (byDayContent[byDayContent.length - 1]?.destinations?.length ?? 0) > 1 &&
                byDayContent[byDayContent.length - 1]?.destinations?.[0]?.id === step.destination?.id
            ) {
                byDayContent.pop();
            }

            if (byDayContent) {
                for (const item of byDayContent) {
                    usedContents.push(item.id);
                }
            }

            const correspondingGuides = flatten(
                guides.filter((item) => {
                    return item.destination.id === step.destination?.id;
                }).map((item) => {
                    return item.rubrics.map((rubric) => {
                        return rubric.localization.find((item) => {
                            return item.locale === options.locale;
                        })?.description;
                    }).filter((item): item is NonNullable<typeof item> => {
                        return !!item;
                    });
                })
            );
            return {
                type: 'program-details',
                anchor: `program-details-${destination}-${step.id}`,
                showInSummary: false,
                summaryTitle: 'DÉTAILS DE VOTRE PROGRAMME',
                title: destination,
                destinationInfo: (byDayContent?.length ?? 0) > 0 ?
                    {
                        type: 'gir',
                        contents: byDayContent!.map((item) => {
                            const localization = item.localization.find((item) => {
                                return item.locale === options.locale;
                            });

                            let title = '';

                            if (localization?.title && localization.title.length > 0) {
                                title = localization.title;
                            } else if (item.title) {
                                title = item.title;
                            }

                            let description = '';

                            if (localization?.long_description && !htmlHasEmptyContent(localization.long_description)) {
                                description += localization.long_description;
                            } else if (item.long_description && !htmlHasEmptyContent(item.long_description)) {
                                description += item.long_description;
                            }

                            if (localization?.short_description && !htmlHasEmptyContent(localization.short_description)) {
                                description += localization.short_description;
                            } else if (item.short_description && !htmlHasEmptyContent(item.short_description)) {
                                description += item.short_description;
                            }

                            const descriptionHtml = description;
                            const { document } = createDomObjectsFromHtml(descriptionHtml);
                            const bodyWrapper = document.body.querySelector('.body-wrapper') as HTMLDivElement | null;
                            if (bodyWrapper) {
                                bodyWrapper.style.margin = '0';
                            }

                            if (quotationCode === 'cercledesvoyages') {
                                const hardCodedContent = HardCodedContentManifest.find((item) => {
                                    return item.trip === options.trip.trip_reference;
                                });
                                const contentItem = hardCodedContent?.replace.find((content) => {
                                    return item.day.length === 1 &&
                                        content.type === 'day' &&
                                        content.id === item.day[0];
                                });
                                if (
                                    hardCodedContent &&
                                    contentItem
                                ) {
                                    if (contentItem.hasTitle) {
                                        try {
                                            title = require(`./html/titles/cercles-${hardCodedContent.trip}-day-${item.day[0]}.txt`).default;
                                        } catch (error) {
                                            if (!(error as Error).message?.includes('Cannot find module')) {
                                                throw error;
                                            }
                                        }
                                    }
                                    let html = '';
                                    try {
                                        html = require(`./html/cercles-${hardCodedContent.trip}-day-${item.day[0]}.html?raw`).default;
                                    } catch (error) {
                                        html = document.body.innerHTML;
                                        if (!(error as Error).message?.includes('Cannot find module')) {
                                            throw error;
                                        }
                                    }
                                    return {
                                        day: options.t('roadbook.gir-day', { day: item.day.join(', ') }),
                                        title: title,
                                        description: html
                                    };
                                }
                            }

                            for (const styleNode of document.body.getElementsByTagName('style')) {
                                styleNode.remove();
                            }

                            for (const table of document.body.getElementsByTagName('table')) {
                                table.replaceWith(...table.children);
                            }

                            fixNodeEmptyContent(document.body);

                            return {
                                day: options.t('roadbook.gir-day', { day: item.day.join(', ') }),
                                title: title,
                                description: document.body.innerHTML
                            };
                        })
                    } :
                    {
                        type: 'text',
                        content: (() => {
                            const content = byStepContent ?
                                (byStepContentLocalization?.long_description ?? byStepContent.long_description ?? '') + '<p>&nbsp;</p>' +
                                (byStepContentLocalization?.short_description ?? byStepContent.short_description ?? '') :
                                correspondingGuides.join('<p>&nbsp;</p>') ?? ''
                            const { document } = createDomObjectsFromHtml(content);

                            for (const styleNode of document.body.getElementsByTagName('style')) {
                                styleNode.remove();
                            }

                            for (const table of document.body.getElementsByTagName('table')) {
                                table.replaceWith(...table.children);
                            }

                            fixNodeEmptyContent(document.body);

                            return document.body.innerHTML;
                        })()
                    },
                destinationMap: nightsCount >= 2 ?
                    {
                        bigNounPinUrl: BigPin,
                        title: `Carte ${destination}`,
                        url: createDestinationMapPageUrl({
                            center: `${step.destination?.data?.latitude},${step.destination?.data?.longitude}`,
                            zoom: step.destination?.data?.zoom_level?.toString() ?? '7',
                            points: filteredActivities.map((item, index) => {
                                return {
                                    label: (index + 1).toString(),
                                    color: item.type === 'transport' ?
                                        '0xBF8A68FF' :
                                        '0x4E69AFFF',
                                    latitude: item.latitude,
                                    longitude: item.longitude
                                };
                            }).concat(
                                hotels.filter((item) => {
                                    if (item.type === 'normal') {
                                        return item.accommodation.hotel[0]?.latitude &&
                                            item.accommodation.hotel[0]?.longitude;
                                    }
                                    return item.accommodation.latitude &&
                                        item.accommodation.longitude;
                                }).map((item, index) => {
                                    return {
                                        label: (filteredActivities.length + index + 1).toString(),
                                        color: '0x000000FF',
                                        latitude: item.type === 'normal' ?
                                            item.accommodation.hotel[0]?.latitude ?? '0' :
                                            item.accommodation.latitude ?? '0',
                                        longitude: item.type === 'normal' ?
                                            item.accommodation.hotel[0]?.longitude ?? '0' :
                                            item.accommodation.longitude ?? '0'
                                    };
                                })
                            )
                        }),
                        products: filteredActivities.map((item) => {
                            return {
                                url: item.type === 'transport' ?
                                    ActivityPin :
                                    PoiPin,
                                type: item.type,
                                text: item.title ?? '',
                                place: item.place
                            };
                        }).concat(
                            hotels.map((item) => {
                                return {
                                    url: AccommodationPin,
                                    type: 'accommodation',
                                    text: item.type === 'normal' ?
                                        item.accommodation.localization.find((item) => {
                                            return item.locale === options.locale;
                                        })?.name ??
                                        item.accommodation.hotel[0]?.name ??
                                        '' :
                                        item.accommodation.localization?.find((item) => {
                                            return item.locale === options.locale;
                                        })?.name ??
                                        item?.accommodation.name,
                                    place: undefined
                                };
                            })
                        )
                    } :
                    undefined,
                destinationOnRoad: nextStep ?
                    {
                        from: destination,
                        to: nextDestination,
                        url: await createDestinationOnRoadPageUrl({
                            from: step,
                            to: nextStep,
                            products: poiRoad
                        }),
                        products: poiRoad.map((item) => {
                            const [longDescription, shortDescription] = getPoiDescription(item, options);
                            return {
                                title: options.t(
                                    'roadbook.destination-on-road-product-title',
                                    {
                                        date: window.moment.utc(item.poi.start_date).format('DD/MM'),
                                        title: getPoiName(item, options)
                                    }
                                ),
                                description: shortDescription + '\n' + longDescription,
                                place: getPoiPlace(item)
                            };
                        })
                    } :
                    undefined,
                date: options.t(
                    'roadbook.program-details-destination-date',
                    {
                        from: window.moment.utc(step.start_date).format('ddd DD MMMM'),
                        to: window.moment.utc(step.end_date).format('ddd DD MMMM'),
                        count: nightsCount
                    }
                ),
                products: transformFlightsToProgramDetailsItems({
                    cart: options.cart,
                    startDate: step.start_date,
                    endDate: step.end_date,
                    nextStartDate: nextStep?.start_date,
                    prevEndDate: prevStep?.end_date,
                    t: options.t
                }).concat(
                    activities as any //@TODO: fix this
                ).concat(
                    transformCarsToProgramDetailsItems({
                        locale: options.locale,
                        cart: options.cart,
                        destinationId: step.destination?.id ?? null,
                        startDate: step.start_date,
                        endDate: step.end_date,
                        t: options.t
                    })
                ).concat(
                    transformTransfersToProgramDetailsItems({
                        locale: options.locale,
                        cart: options.cart,
                        destinationId: step.destination?.id ?? null,
                        startDate: step.start_date,
                        endDate: step.end_date,
                        t: options.t
                    })
                ).concat(
                    transformTrainsToProgramDetailsItems({
                        locale: options.locale,
                        cart: options.cart,
                        destinationId: step.destination?.id ?? null,
                        startDate: step.start_date,
                        endDate: step.end_date,
                        t: options.t
                    }) as any //@TODO: fix this
                ).concat(
                    transformCruisesToProgramDetailsItems({
                        locale: options.locale,
                        cart: options.cart,
                        destinationId: step.destination?.id ?? null,
                        startDate: step.start_date,
                        endDate: step.end_date,
                        t: options.t
                    }) as any //@TODO: fix this
                ).concat(
                    transformFerriesToProgramDetailsItems({
                        locale: options.locale,
                        cart: options.cart,
                        destinationId: step.destination?.id ?? null,
                        startDate: step.start_date,
                        endDate: step.end_date,
                        t: options.t
                    }) as any //@TODO: fix this
                ).sort((a, b) => {
                    return window.moment.utc(a.dateOrder).isBefore(window.moment.utc(b.dateOrder)) ?
                        -1 :
                        1;
                }),
                suggestions: options.cart.pois.filter((item) => {
                    return item.poi.poi_type &&
                        !item.poi.is_optional &&
                        (
                            window.moment.utc(item.poi.start_date).isBetween(
                                window.moment.utc(step.start_date),
                                window.moment.utc(step.end_date)
                            ) ||
                            window.moment.utc(item.poi.end_date).isBetween(
                                window.moment.utc(step.start_date),
                                window.moment.utc(step.end_date)
                            ) ||
                            (
                                window.moment.utc(step.start_date).isBetween(
                                    window.moment.utc(item.poi.start_date),
                                    window.moment.utc(item.poi.end_date)
                                ) &&
                                window.moment.utc(step.end_date).isBetween(
                                    window.moment.utc(item.poi.start_date),
                                    window.moment.utc(item.poi.end_date)
                                )
                            )
                        );
                }).map((item) => {
                    const [longDescription, shortDescription] = getPoiDescription(item, options);
                    return {
                        date: window.moment.utc(item.poi.start_date).format('DD/MM'),
                        title: getPoiName(item, options),
                        description: shortDescription + '\n' + longDescription
                    };
                }),
                accommodations: accommodations.length === 0 && nightsCount === 0 ?
                    undefined :
                    accommodations
            };
        })
    );
    return programs.filter((item) => {
        return item.type === 'program-details' &&
            (
                item.destinationMap ||
                item.destinationOnRoad ||
                item.products.length > 0 ||
                (item.accommodations?.length ?? 0) > 0 ||
                (item.suggestions?.length ?? 0) > 0
            );
    }).map((item, index) => ({
        ...item,
        showInSummary: index === 0
    }));
}
