//---- Functions ----//
import GetCancelPenalty from "./GetCancelPenalty";
import GetChangePenalty from "./GetChangePenalty";
import SortObject from "./SortObject";
import GetFlight from "./GetFlight";

//---- Actions ----//
import { SetFetchedData, SetFetchedDataStatus, AddFetchData, CountProvider } from "../../../Actions/FlightSearch";

// eslint-disable-next-line max-params
export default (group, dispatch, data, pax_passenger, mode, config) => {
    const is_km = group.is_km;

    let filter_time = [];
    group.journey.map(() => {
        filter_time.push({
            departure: [0, 24],
            arrival: [0, 24]
        });
    });

    let alliances = [];
    let provider_arr = [];
    let stations = {};
    let airlines = {};
    let airports = {};
    let stopover_airport = [];
    let stopover_duration = [];
    let flights = [];
    let min_price = 0;
    let max_price = 0;
    let stop_over = {
	    none: 0,
	    one: 0,
	    more: 0
    };
    let max_duration = 0;
    let min_duration = 0;

    //---- Alliances & Airlines code ----//
    Object.keys(data.airlines).forEach(key => {
	    let add_alliance = true;
	    for (let j = 0; j < alliances.length; j++) {
	        if (data.airlines[key].alliance === null) {
	            add_alliance = false;
	        } else if (data.airlines[key].alliance.commercial_name === alliances[j].name) {
	            add_alliance = false;
	        }
	    }
	    if (add_alliance && data.airlines[key].alliance !== null) {
	        alliances.push({
	            displayed: false,
	            name: data.airlines[key].alliance.commercial_name,
	            price: 0
	        });
	    }
	    airlines[key] = {
	        displayed: false,
	        name: data.airlines[key].commercial_name,
	        code: data.airlines[key].airline_code,
	        price: 0,
	        id: data.airlines[key].id
	    };
    });
    airlines = SortObject(airlines, "name");

    //---- Airports code ----//
    Object.keys(data.airports).forEach(key => {
	    airports[key] = {
	        displayed: false,
	        code: data.airports[key].airport_code,
	        name: data.airports[key].name,
	        price: 0,
	        international_name: data.airports[key].international_name,
	        id: data.airports[key].id,
	        iata_city: data.airports[key].iata_city,
	        weight: data.airports[key].weight
	    };
    });

    //---- Stations code ----//
    Object.keys(data.stations).forEach(key => {
	    stations[key] = {
	        displayed: false,
	        code: data.stations[key].station_code,
	        price: 0,
	        international_name: data.stations[key].international_name,
	        id: data.stations[key].id,
	        iata_city: data.stations[key].iata_city
	    };
    });
    for (let i = 0; i < group.journey.length; i++) {
	    stopover_airport.push({});
	    stopover_duration.push({
	        two_hours: false,
	        two_ten_hours: false,
	        ten_hours: false,
	        displayed: false
	    });
    }
    
    //---- Flight List code ----//
    for (let i = 0; i < data.flight.length; i++) {
	    let adult = null;
	    let children = null;
	    let baby = null;
	    let total_price = 0;
	    let total_cancel_penalty = null;
	    if (data.flight[i].pax_pricing !== undefined && data.flight[i].pax_pricing !== null) {
		    for (let j = 0; j < data.flight[i].pax_pricing.length; j++) {
		        if (data.flight[i].pax_pricing[j].pax_code === "ADT" || data.flight[i].pax_pricing[j].pax_code === "ITX") {
		            adult = {
		                cancel_penalty: GetCancelPenalty(data.flight[i].pax_pricing[j]),
		                change_penalty: GetChangePenalty(data.flight[i].pax_pricing[j]),
		                margin: data.flight[i].pax_pricing[j].margin,
		                taxes: data.flight[i].pax_pricing[j].taxes,
		                total_price: data.flight[i].pax_pricing[j].prices[0].selling_price,
		                number: pax_passenger.ADT,
                        pax_code: data.flight[i].pax_pricing[j].pax_code
		            };
		        } else if (data.flight[i].pax_pricing[j].pax_code === "CNN" || data.flight[i].pax_pricing[j].pax_code === "INN") {
		            children = {
		                cancel_penalty: GetCancelPenalty(data.flight[i].pax_pricing[j]),
		                change_penalty: GetChangePenalty(data.flight[i].pax_pricing[j]),
		                margin: data.flight[i].pax_pricing[j].margin,
		                taxes: data.flight[i].pax_pricing[j].taxes,
		                total_price: data.flight[i].pax_pricing[j].prices[0].selling_price,
		                number: pax_passenger.CNN,
                        pax_code: data.flight[i].pax_pricing[j].pax_code
		            };
		        } else if (data.flight[i].pax_pricing[j].pax_code === "INF") {
		            baby = {
		                cancel_penalty: GetCancelPenalty(data.flight[i].pax_pricing[j]),
		                change_penalty: GetChangePenalty(data.flight[i].pax_pricing[j]),
		                margin: data.flight[i].pax_pricing[j].margin,
		                taxes: data.flight[i].pax_pricing[j].taxes,
		                total_price: data.flight[i].pax_pricing[j].prices[0].selling_price,
		                number: pax_passenger.INF,
                        pax_code: data.flight[i].pax_pricing[j].pax_code
		            };
		        }
		        // eslint-disable-next-line max-len
		        total_price = (adult !== null ? (adult.number * (parseFloat(adult.total_price) + parseFloat(adult.taxes[0].selling_price))) : 0) + (children !== null ? (children.number * (parseFloat(children.total_price) + parseFloat(children.taxes[0].selling_price))) : 0) + (baby !== null ? (baby.number * (parseFloat(baby.total_price) + parseFloat(baby.taxes[0].selling_price))) : 0);
		        if (adult !== null) {
		            if (adult.cancel_penalty !== null) {
		                total_cancel_penalty += adult.number * adult.cancel_penalty;
		            } else {
		                total_cancel_penalty = null;
		            }
		        }
		        if (children !== null) {
		            if (children.cancel_penalty !== null) {
		                total_cancel_penalty += children.number * children.cancel_penalty;
		            } else {
		                total_cancel_penalty = null;
		            }
		        }
		        if (baby !== null) {
		            if (baby.cancel_penalty !== null) {
		                total_cancel_penalty += baby.number * baby.cancel_penalty;
		            } else {
		                total_cancel_penalty = null;
		            }
		        }
		    }
	    }
		data.flight[i].trace_id = data.trace_id;
	    let provider_id = data.flight[i].provider_id;
	    let fare_type = data.flight[i].fare_type;
        let flight_source = data.flight[i].flight_source;
	    let found = provider_arr.find((el) => {
	        return el.id === provider_id;
	    });
	    if (found === undefined) {
	        provider_arr.push({
	            id: provider_id,
	            displayed: false
	        });
	    }
	    let refundable = total_cancel_penalty !== null ? (total_cancel_penalty < parseFloat(total_price)) : null;
        let flight_key = data.flight[i].flight_key !== undefined ? data.flight[i].flight_key : null;
        let flight_name = data.flight[i].name !== undefined ? data.flight[i].name : null;
        let no_time_data = data.flight[i].no_time_data !== undefined ? data.flight[i].no_time_data : false;
        let need_segment_key = data.need_segment_key;
        let last_ticketing_date = data.flight[i].last_ticketing_date !== undefined ? data.flight[i].last_ticketing_date : null;
	    // eslint-disable-next-line max-len
	    GetFlight(flights, [], data.flight[i].outbound_list, flight_key, 0, adult, children, baby, stop_over, 0, refundable, total_price, alliances, data.airlines, airlines, airports, data.distance_unit, is_km, stopover_airport, stations, provider_id, fare_type, flight_source, flight_name, no_time_data, need_segment_key, last_ticketing_date, data.trace_id);
    }
    let sort_flight = flights.sort((a, b) => {
	    return (parseFloat(a.total_price) - parseFloat(b.total_price));
    });
    let final_flight = [...sort_flight];
    // if (sort_flight.length > 500) {
    //     for (let count = 0; count <= 500; count++) {
    //         final_flight.push(sort_flight[count]);
    //     }
    // }else{
    // 	final_flight = sort_flight;
    // }
    final_flight.map((flight) => {
        flight.outbounds.map((outbound) => {
	        if (max_duration === 0 || Math.floor(outbound.flight_duration / 60) > max_duration) {
	            max_duration = Math.floor(outbound.flight_duration / 60);
	        }
	        if (min_duration === 0 || Math.ceil(outbound.flight_duration / 60) < min_duration) {
	            min_duration = Math.ceil(outbound.flight_duration / 60);
	        }
	    });

        let total_price = 0;
        if (flight.adult !== null) {
            total_price += flight.adult.number * (parseFloat(flight.adult.total_price) + parseFloat(flight.adult.taxes[0].selling_price));
        }
        if (flight.children !== null) {
            total_price += flight.children.number * (parseFloat(flight.children.total_price) + parseFloat(flight.children.taxes[0].selling_price));
        }
        if (flight.baby !== null) {
            total_price += flight.baby.number * (parseFloat(flight.baby.total_price) + parseFloat(flight.baby.taxes[0].selling_price));
        }

        if (min_price === 0 || total_price < min_price) {
	        min_price = Math.floor(total_price);
	    }
	    if (max_price === 0 || total_price > max_price) {
	        max_price = Math.ceil(total_price);
	    }
    });
    
    for (let i = 0; i < stopover_airport.length; i++) {
	    if (Object.keys(stopover_duration[i]).length !== 0) {
	        stopover_duration[i].displayed = true;
	    }
    }
    let luggage = config.quotation_code === "amerigo";

    if (mode === "add_flights") {
        dispatch(
            AddFetchData(
                alliances,
                airlines,
                airports,
                final_flight.length > 0 ? final_flight : sort_flight,
                max_duration,
                max_price,
                min_duration,
                min_price,
                stations,
                stop_over,
                stopover_airport,
                data.trace_id
            )
        );
    } else {
        dispatch(
            SetFetchedData(
                final_flight.length > 0 ? final_flight : sort_flight,
                airports,
                airlines,
                alliances,
                min_price,
                max_price,
                stop_over,
                data.currency,
                data.currency_symbol,
                data.distance_unit,
                data.trace_id,
                stopover_airport,
                filter_time,
                max_duration,
                min_duration,
                stations,
                luggage,
                stopover_duration,
                provider_arr
            )
        );
    }
    dispatch(SetFetchedDataStatus("Done"));
    dispatch(CountProvider());
    // dispatch(DisplayList(final_flight.length > 0 ? final_flight : sort_flight))
};
