import CheckBeforeRequest from "../../Common/CheckBeforeRequest";

import CountAgePaxType from "./CountAgePaxType";

import { SetGetOptions, SetOptionsError, SetOptionLoaded } from "../../../Actions/FlightSearch";

import axios from "axios";

export default (flight, flight_providers, flight_index, searched_group, trip_id, tripversion_id, trip_currency, trace_id, airlines, airports, stations, end_date, dispatch, setSelecting, user, setStateOptionLoad) => {
    let { pass_check, headers } = CheckBeforeRequest();
    let pax_obj = CountAgePaxType(searched_group.travelers, end_date);
    let quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    console.log('flight:', flight);
    let fare_type = "all";
    if (quotation_code === "visiteurs" && user.client_full.type === 2) {
        fare_type = "private"
    }
    if (searched_group.private_price && !searched_group.public_price) {
        fare_type = "private";
    } else if (searched_group.public_price && !searched_group.private_price) {
        fare_type = "public";
    }
    let pax_type = `ADT:${pax_obj.ADT},CNN:${pax_obj.CNN},INF:${pax_obj.INF}`;
    if (['1G'].includes(flight_providers.find(item => item.id === flight.provider_id).code)) {
        let find_itx = flight.adult.pax_code === 'ITX';
        if (find_itx) {
            pax_type = `ITX:${pax_obj.ADT},INN:${pax_obj.CNN},INF:${pax_obj.INF}`;
            fare_type = "private";
        } else {
            fare_type = "public";
        }
    }

    if (['sabre_rest'].includes(flight_providers.find(item => item.id === flight.provider_id).code) && fare_type === "private") {
        pax_type = `ITX:${pax_obj.ADT},INN:${pax_obj.CNN},INF:${pax_obj.INF}`;
    }

    let params = {
        trip: trip_id,
        trip_version: tripversion_id,
        currency: trip_currency.iso_code,
        trace_id: flight.trace_id,
        pax_type: pax_type,
        provider_id: flight.provider_id,
        fare_type: fare_type
    }

    if (flight.need_segment_key) {
        let all_outbound_segments = [];

        flight.outbounds.map((outbound_flight, outbound_index) => {
            let outbound_segment = [];
            outbound_flight.legs.map((leg, leg_index) => {
                let marketing_airline = airlines.find(item => item.id === leg.marketing_airline);
                outbound_segment.push(leg.segment_key
                                    + "," + (leg.marketing_airline.airline_code ? leg.marketing_airline.airline_code : marketing_airline.code)
                                    + "," + (leg.marketing_flight_number ? leg.marketing_flight_number : leg.flight_number)
                                    + "," + (leg.origin_station ? stations[leg.origin_station].code : (leg.origin_airport ? airports[leg.origin_airport].code : leg.origin.airport_code))
                                    + "," + (leg.destination_station ? stations[leg.destination_station].code : (leg.destination_airport ? airports[leg.destination_airport].code : leg.destination.airport_code))
                                    + "," + (leg.departure_datetime_locale ? leg.departure_datetime_locale : leg.departure_time)
                                    + "," + (leg.arrival_datetime_locale ? leg.arrival_datetime_locale : leg.arrival_time)
                                    + "," + outbound_index + "," + leg.booking_code + "," + leg.fare_basis + (leg.origin_station ? ",true" : ",false"))
            })
            outbound_segment = outbound_segment.join('|');
            all_outbound_segments.push(outbound_segment);
        })
        params.outbound_segments = all_outbound_segments.join("|");
    }

    params.flight_key = flight.flight_key;

    if (pass_check) {
        axios({
            method: "POST",
            url: `${API_HREF}client/${window.id_owner}/flights/option/`,
            data: params,
            headers: headers
        }).then(function(response) {
            let segments = Object.keys(response.data.segments).reduce(function (r, a) {
                response.data.segments[a].key_segments = a;
                r[response.data.segments[a].group] = r[response.data.segments[a].group] || [];
                r[response.data.segments[a].group].push(response.data.segments[a]);
                return r;
            }, []);
            response.data.segments = segments;
            dispatch(SetGetOptions(flight.id, response.data));
            if (response.data.iti_increased || response.data.iti_reduced || response.data.iti_shift_backward || response.data.iti_shift_forward) {
                dispatch({type: 'FLIGHT_OPTION_CONTROL', payload: response.data});
                dispatch({type: 'FLIGHT_OPTION_CONTROL_RECALC', payload: true});
            } else {
                dispatch({type: 'FLIGHT_OPTION_CONTROL', payload: null});
                dispatch({type: 'FLIGHT_OPTION_CONTROL_RECALC', payload: false});
            }
            if (setStateOptionLoad !== undefined) {
                setStateOptionLoad(true);
            } else {
                dispatch(SetOptionLoaded(true));
            }
        }).catch(function(error) {
            setSelecting(false);
            console.log('error:', error);
            if (error.responseJSON !== undefined) {
                dispatch(SetOptionsError(error.responseJSON.detail, flight.id));
            } else {
                dispatch(SetOptionsError(error.response.data.errors, flight.id));
            }
        });
    }
}