//Dependencies
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { Fragment } from 'react';
//Core
import { Typography } from '@mui/material';
import Grid from '@material-ui/core/Grid';
import Close from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useGetPrice } from "./utils/getPrice";
import { useGetTaxesPrice } from './utils/getTaxesPrice';

const useStyles = makeStyles({
    hr: {
        margin: '10px 0 0 0'
    },
    dateContainer: {
        textAlign: 'center',
        border: '1px solid'
    }
});

const CartFlightPriceDetail = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user);
    const language = useSelector(state => state.header.tmp_language);
    const price_detail = useSelector(state => state.cart.flight_price_detail);
    const currency = useSelector(state => state.trip.currency);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const getPrice = useGetPrice();
    const getTaxesPrice = useGetTaxesPrice();
    const onClose = () => {
        dispatch({ type: 'CART_TOGGLE_FLIGHT_PRICE_DETAIL', payload: null });
    };
    console.log('price_detail:', price_detail);
    if (price_detail !== null) {
        let seatsCost = 0;
        let option_cost = 0;
        let option_on_flight = [];
        let optionCurrency = null;
        let seatsCurrency = null;
        price_detail.outbounds.map(outbound => {
            let option_on_group = [];
            outbound.legs.map(leg => {
                for (const seat of leg.seats ?? []) {
                    let seatPrice = 0;
                    if ((seat.prices?.length ?? 0) > 0) {
                        seatPrice = getPrice(seat.prices).cost;
                        seatsCurrency = seatPrice.currency;
                    } else {
                        seatPrice = parseFloat(seat.price ?? '0');
                    }
                    seatsCost += seatPrice;
                }

                if (leg.paid_options && leg.paid_options.length > 0) {
                    leg.paid_options.map(option => {
                        let optionPrice = 0;
                        if ((option.prices?.length ?? 0) > 0) {
                            optionPrice = getPrice(option.prices).cost;
                            optionCurrency = getPrice(option.prices).currency;
                        } else {
                            optionPrice = parseFloat(option.price);
                        }
                        if (option.option_on_flight) {
                            if (!option_on_flight.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                option_cost += optionPrice;
                                option_on_flight.push(`${option.key_optional_service}-${option.traveler}`);
                            }
                        } else if (option.option_on_group) {
                            if (!option_on_group.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                option_cost += optionPrice;
                                option_on_group.push(`${option.key_optional_service}-${option.traveler}`);
                            }
                        } else {
                            option_cost += optionPrice;
                        }
                    });
                }
            });
        });
        return (
            <Dialog fullScreen={fullScreen} open={true} fullWidth maxWidth={'md'} onClose={onClose} disableEscapeKeyDown>
                <DialogTitle onClose={onClose}>
                    <Grid container justify={'space-between'} alignItems={'center'}>
                        {
                            fullScreen ? (
                                <Grid item>
                                    <IconButton edge={'start'} onClick={onClose}><NavigateBefore /></IconButton> {t('cart-material.price-detail')}
                                </Grid>
                            ) : (
                                <Fragment>
                                    <Grid item>
                                        {t('cart-material.price-detail')} :
                                    </Grid>
                                    <Grid item>
                                        <IconButton onClick={onClose}><Close /></IconButton>
                                    </Grid>
                                </Fragment>
                            )
                        }
                    </Grid>
                    <hr className={classes.hr} />
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        {
                            price_detail.pax_price.map(pax_price => (
                                <Grid item xs={12}>
                                    <Typography>
                                        <strong>
                                            {t('flight_search.ticket_' + pax_price.pax_code)}{' '}
                                            (x{price_detail.passenger[pax_price.pax_code === 'ITX' ? 'ADT' : pax_price.pax_code === 'INN' ? 'CNN' : pax_price.pax_code]}) :{' '}
                                        </strong>
                                        {price_detail.passenger[pax_price.pax_code === 'ITX' ? 'ADT' : pax_price.pax_code === 'INN' ? 'CNN' : pax_price.pax_code]} *{' '}
                                        {
                                            new Intl.NumberFormat(
                                                language,
                                                {
                                                    style: 'currency',
                                                    currency: currency?.iso_code ?? 'EUR'
                                                }
                                            ).format(
                                                user.client_full === 2 ?
                                                    (parseFloat(pax_price.prices_agency) + getTaxesPrice(pax_price.taxes_agency)) :
                                                    (parseFloat(pax_price.prices) + getTaxesPrice(pax_price.taxes))
                                            )
                                        }
                                        {' '}={' '}
                                        {
                                            new Intl.NumberFormat(
                                                language,
                                                {
                                                    style: 'currency',
                                                    currency: currency?.iso_code ?? 'EUR'
                                                }
                                            ).format(
                                                user.client_full === 2 ?
                                                    ((parseFloat(pax_price.prices_agency) + getTaxesPrice(pax_price.taxes_agency)) * price_detail.passenger[pax_price.pax_code === 'ITX' ? 'ADT' : pax_price.pax_code === 'INN' ? 'CNN' : pax_price.pax_code]) :
                                                    ((parseFloat(pax_price.prices) + getTaxesPrice(pax_price.taxes)) * price_detail.passenger[pax_price.pax_code === 'ITX' ? 'ADT' : pax_price.pax_code === 'INN' ? 'CNN' : pax_price.pax_code])
                                            )
                                        }
                                    </Typography>
                                    <Typography sx={{ marginLeft: 3 }}>
                                        <strong>
                                            {t('flight_search.flight_without_taxe')} :{' '}
                                        </strong>
                                        {price_detail.passenger[pax_price.pax_code === 'ITX' ? 'ADT' : pax_price.pax_code === 'INN' ? 'CNN' : pax_price.pax_code]} *{' '}
                                        {
                                            new Intl.NumberFormat(
                                                language,
                                                {
                                                    style: 'currency',
                                                    currency: currency?.iso_code ?? 'EUR'
                                                }
                                            ).format(
                                                user.client_full === 2 ?
                                                    parseFloat(pax_price.prices_agency) :
                                                    parseFloat(pax_price.prices)
                                            )
                                        }
                                        {' '}={' '}
                                        {
                                            new Intl.NumberFormat(
                                                language,
                                                {
                                                    style: 'currency',
                                                    currency: currency?.iso_code ?? 'EUR'
                                                }
                                            ).format(
                                                user.client_full === 2 ?
                                                    ((parseFloat(pax_price.prices_agency)) * price_detail.passenger[pax_price.pax_code === 'ITX' ? 'ADT' : pax_price.pax_code === 'INN' ? 'CNN' : pax_price.pax_code]) :
                                                    ((parseFloat(pax_price.prices)) * price_detail.passenger[pax_price.pax_code === 'ITX' ? 'ADT' : pax_price.pax_code === 'INN' ? 'CNN' : pax_price.pax_code])
                                            )
                                        }
                                    </Typography>
                                    <Typography sx={{ marginLeft: 3 }}>
                                        <strong>{t('flight_search.taxe_price')} : </strong>
                                        {price_detail.passenger[pax_price.pax_code === 'ITX' ? 'ADT' : pax_price.pax_code === 'INN' ? 'CNN' : pax_price.pax_code]} *{' '}
                                        {
                                            new Intl.NumberFormat(
                                                language,
                                                {
                                                    style: 'currency',
                                                    currency: currency?.iso_code ?? 'EUR'
                                                }
                                            ).format(
                                                user.client_full === 2 ?
                                                getTaxesPrice(pax_price.taxes_agency) :
                                                getTaxesPrice(pax_price.taxes)
                                            )
                                        }
                                        {' '}={' '}
                                        {
                                            new Intl.NumberFormat(
                                                language,
                                                {
                                                    style: 'currency',
                                                    currency: currency?.iso_code ?? 'EUR'
                                                }
                                            ).format((user.client_full === 2 ? getTaxesPrice(pax_price.taxes_agency) : getTaxesPrice(pax_price.taxes)) * price_detail.passenger[pax_price.pax_code === 'ITX' ? 'ADT' : pax_price.pax_code === 'INN' ? 'CNN' : pax_price.pax_code])
                                        }
                                    </Typography>
                                </Grid>
                            ))
                        }
                        {
                            seatsCost > 0 &&
                            <Grid item xs={12}>
                                <Typography sx={{ marginLeft: 3 }}>
                                    <strong>{t('flight_search.seats')}</strong>
                                    {' '}
                                    {
                                        new Intl.NumberFormat(
                                            language,
                                            {
                                                style: 'currency',
                                                currency: seatsCurrency?.is_code ?? currency?.iso_code ?? 'EUR'
                                            }
                                        ).format(seatsCost)
                                    }
                                </Typography>
                            </Grid>
                        }
                        {
                            option_cost > 0 &&
                            <Grid item xs={12}>
                                <Typography sx={{ marginLeft: 3 }}>
                                    <strong>{t('flight_search.paid_options')} : </strong>
                                    {
                                        new Intl.NumberFormat(
                                            language,
                                            {
                                                style: 'currency',
                                                currency: optionCurrency?.is_code ?? currency?.iso_code ?? 'EUR'
                                            }
                                        ).format(option_cost)
                                    }
                                </Typography>
                            </Grid>
                        }
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }
    return null;
};

export default CartFlightPriceDetail;
