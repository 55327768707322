import FlightData from "./FlightData";

function allPossibleCases(arr) {
    if (arr.length === 0) {
        return [];
    } 
    else if (arr.length === 1){
        return arr[0].choices;
    }
    else {
        let result = [];
        let allCasesOfRest = allPossibleCases(arr.slice(1));  // recur with the rest of array
        for (let c in allCasesOfRest) {
            for (let i = 0; i < arr[0].choices.length; i++) {
                let d = [];
                let a = allCasesOfRest[c];
                let b = arr[0].choices[i];
                d.push(b);
                d = d.concat(a)
                result.push(d);
            }
        }
        return result;
    }
}
export default function GetFlight(flights, outbounds, outbound_list, flight_key, index_outbound, adult, children, baby, stop_over, flight_stop_over, refundable, total_price, alliances, old_airlines, airlines, airports, distance_unit, is_km, stopover_airport, stations, provider_id, fare_type, flight_source, flight_name, no_time_data, need_segment_key, last_ticketing_date, trace_id) {
    if (outbound_list.length < 2) {
        if (outbound_list.length !== 0) {
            outbound_list[index_outbound].choices.map((segment, segment_index) => {
                if (index_outbound === (outbound_list.length - 1)) {
                    let tmp_outbounds = outbounds.slice();
                    tmp_outbounds.push(segment);
                    FlightData(tmp_outbounds, flights, flight_key, adult, children, baby, stop_over, flight_stop_over, refundable, total_price, alliances, old_airlines, airlines, airports, distance_unit, is_km, stopover_airport, stations, provider_id, fare_type, flight_source, flight_name, no_time_data, need_segment_key, need_segment_key, last_ticketing_date, trace_id);
                } else {
                    if (segment_index){
                        outbounds.pop();
                    }
                    if (flight_stop_over < segment.legs.length) {
                        flight_stop_over = segment.legs.length;
                    }
                    segment.legs.map((leg, leg_index) => {
                        airlines.map((airline) => {
                            let leg_airline = null;
                            if (airline.id === leg.marketing_airline) {
                                leg_airline = airline;
                                if (leg_airline.code !== "" && (leg_airline.price === 0 || leg_airline.price > total_price)) {
                                    leg_airline.price = total_price;
                                }
                            }
                        });
                        for (let k = 0; k < alliances.length; k++) {
                            if (old_airlines[leg.marketing_airline].alliance !== null && alliances[k].name === old_airlines[leg.marketing_airline].alliance.commercial_name && (alliances[k].price === 0 || alliances[k].price > total_price)) {
                                leg.alliance = Object.assign({}, alliances[k]);
                                alliances[k].price = total_price;
                            }
                        }
                        if (leg_index > 0 && leg_index <= segment.legs.length - 1) {
                            if (leg.origin_airport !== undefined){
                                if (leg_index <= segment.legs.length - 1 && !stopover_airport[segment_index].hasOwnProperty(leg.origin_airport)) {
                                    stopover_airport[segment_index][leg.origin_airport] = airports[leg.origin_airport];
                                }
                                if (leg_index <= segment.legs.length - 1 && (stopover_airport[segment_index][leg.origin_airport].price === 0 || stopover_airport[segment_index][leg.origin_airport].price > total_price)) {
                                    stopover_airport[segment_index][leg.origin_airport].price = total_price;
                                }
                            }else if (leg.origin_station !== undefined){
                                if (leg_index <= segment.legs.length - 1 && !stopover_airport[segment_index].hasOwnProperty(leg.origin_station)) {
                                    stopover_airport[segment_index][leg.origin_station] = stations[leg.origin_station];
                                }
                                if (leg_index <= segment.legs.length - 1 && (stopover_airport[segment_index][leg.origin_station].price === 0 || stopover_airport[segment_index][leg.origin_station].price > total_price)) {
                                    stopover_airport[segment_index][leg.origin_station].price = total_price;
                                }
                            }
                            
                        }
                    })
                    outbounds.push(segment);
                    GetFlight(flights, outbounds, outbound_list, flight_key, index_outbound + 1, adult, children, baby, stop_over, flight_stop_over, refundable, total_price, alliances, old_airlines, airlines, airports, distance_unit, is_km, stopover_airport, stations, provider_id, fare_type, flight_source, flight_name, no_time_data, need_segment_key, last_ticketing_date, trace_id);
                }
            })
        }
        else {
            FlightData(outbounds, flights, flight_key, adult, children, baby, stop_over, flight_stop_over, refundable, total_price, alliances, old_airlines, airlines, airports, distance_unit, is_km, stopover_airport, stations, provider_id, fare_type, flight_source, flight_name, no_time_data, need_segment_key, last_ticketing_date, trace_id);
        }
    }
    else {
        let r = allPossibleCases(outbound_list);
        r.map((item) => {
            FlightData(item, flights, flight_key, adult, children, baby, stop_over, flight_stop_over, refundable, total_price, alliances, old_airlines, airlines, airports, distance_unit, is_km, stopover_airport, stations, provider_id, fare_type, flight_source, flight_name, no_time_data, need_segment_key, last_ticketing_date, trace_id);
        });
    }
    flight_stop_over = 0;
    outbounds = [];
}