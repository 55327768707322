import { TFunction } from "i18next";
import { useCartProducts } from "../../../Itinerary/network/cartProducts";
import { htmlHasEmptyContent } from "./htmlHasEmptyContent";
import { createDomObjectsFromHtml } from "../MailVisualEditor/utils/dom";
import { fixNodeEmptyContent } from "../MailVisualEditor/utils/fixNodeEmptyContent";
import { getPoiPlace } from "./getPoiPlace";
import { getPoiDescription } from "./getPoiDescription";
import { getPoiName } from "./getPoiName";

export function transformPoiToProgramDetailsItems(
    options: {
        cart: ReturnType<typeof useCartProducts>,
        destinationId: number | null,
        startDate: string,
        endDate: string,
        locale: number | null,
        t: TFunction
    }
) {
    const pois = options.cart.pois.filter((item) => {
        return (
            window.moment.utc(item.poi.start_date).isBetween(
                window.moment.utc(window.moment.utc(options.startDate)),
                window.moment.utc(window.moment.utc(options.endDate)),
                'minutes',
                '[]'
            )
        ) &&
            !item.poi.poi_type &&
            !item.poi.is_optional;
    });

    return pois.map((item) => {
        const [longDescription, shortDescription] = getPoiDescription(item, options);
        let html = [
            shortDescription,
            longDescription
        ].filter((item) => item && !htmlHasEmptyContent(item)).join('');
        const { document } = createDomObjectsFromHtml(html);
        fixNodeEmptyContent(document.body);

        return {
            dateOrder: item.poi.start_date,
            date: window.moment.utc(item.poi.start_date).format('DD/MM'),
            ref: item.poi.booking_status?.item_reference,
            title: (item.poi.hide_hours || item.poi.custom_product?.hide_hours) ? getPoiName(item, options) : options.t(
                    'roadbook.program-details-poi-title',
                    {
                        hourly: window.moment.utc(item.poi.start_date).format('HH[h]mm'),
                        title: getPoiName(item, options)
                    }
                ),
            description: document.body.innerHTML,
            note: item.poi.custom_information?.replace(/\n/g, '<br />') ?? null,
            place: item.poi.custom_product?.hide_localization || item.poi.hide_address ? undefined : getPoiPlace(item),
            latitude: item.type === 'normal' ?
                item.poi.custom_product?.place.latitude ?? '0' :
                item.poi.latitude,
            longitude: item.type === 'normal' ?
                item.poi.custom_product?.place.longitude ?? '0' :
                item.poi.longitude,
            type: !item.poi.poi_type ?
                'transport' :
                'activity'
        };
    });
}
