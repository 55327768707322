import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Typography, makeStyles, useTheme } from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from '@material-ui/core/Switch';

import { useGetTaxesPrice } from "../../CartMaterial/utils/getTaxesPrice";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";

import { SetTripInfoVersion } from "../../../Actions/Menu";

import GetQuotationDefaultInclusions from "./Functions/GetQuotationDefaultInclusions";

import axios from "axios";

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize10: {
        fontSize: 10
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize16: {
        fontSize: 16
    },
    fontSize20: {
        fontSize: 20
    },
    fontSize24: {
        fontSize: 24
    },
    upperCase: {
        textTransform: "uppercase"
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    textCenter: {
        textAlign: "center"
    },
    textRight: {
        textAlign: "right"
    },
    formControlRoot1: {
        "width": "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '18.5px 14px;'
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 15,
            zIndex: 1
        }
    },
    genericPadding: {
        padding: 20
    },
    spacer: {
        padding: "8px 0px"
    },
    greyBackground: {
        backgroundColor: "#EFEFEF"
    },
    circleBorder: {
        borderRadius: 8
    },
    heightInfo: {
        height: 75
    },
    heightBrief: {
        height: 165
    },
    heightInclusion: {
        height: 170
    },
    heightBriefMobile: {
        height: 130
    },
    heightInclusionMobile: {
        height: 100
    },
    cancelButton: {
        color: "#0000008A",
        backgroundColor: "#FFFFFF",
        marginRight: 20
    },
    validateButton: {
        color: "#FFFFFF",
        backgroundColor: "#2C98F0"
    }
}));


const TripListQuotation = (({ trip_displayed_version }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down(768));

    // const quotation_code = 'verdie';
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    const user = useSelector(store => store.user.user);
    const trip_info = useSelector(store => store.menu.trip_info);
    const trip_currency = useSelector(store => store.trip.currency);
    const accommodation_cart = useSelector(store => store.accommodation.sort_cart);
    const car_cart = useSelector(store => store.cars_search.cart);
    const flight_cart = useSelector(store => store.flight.cart);
    const poi_cart = useSelector(store => store.poi.cart);
    const transfer_cart = useSelector(store => store.transfers.cart);
    const manual_cart = useSelector(store => store.cart.manual_item_list);
    const assistance_cart = useSelector(store => store.cart.assistance_cart);
    const currency_list = useSelector(state => state.base.currency_list);

    const { enqueueSnackbar } = useSnackbar();

    const [info, setInfo] = useState("");
    const [brief, setBrief] = useState("");
    const [inclusions, setInclusions] = useState("");
    const [nonInclusions, setNonInclusions] = useState("");
    const [manualFlightConditions, setManualFlightConditions] = useState("");
    const [manualTerrestrialConditions, setManualTerrestrialConditions] = useState("");
    const [flightConditions, setFlightConditions] = useState("");
    const [terrestrialConditions, setTerrestrialConditions] = useState("");
    const [defaultInclusions, setDefaultInclusions] = useState("");
    const [defaultNonInclusions, setDefaultNonInclusions] = useState("");
    const [customInclusions, setCustomInclusions] = useState(false);
    const [customConditions, setCustomConditions] = useState(false);

    const infoLabel = useRef(null);
    const infoLabelWidth = infoLabel.current ? infoLabel.current.clientWidth : 130;
    const briefLabel = useRef(null);
    const briefLabelWidth = briefLabel.current ? briefLabel.current.clientWidth : 80;
    const getTaxesPrice = useGetTaxesPrice();

    useEffect(() =>{
        for (version_index = 0; version_index < trip_info.data.length; version_index++) {
            if (trip_info.data[version_index].id === trip_displayed_version) {
                if (trip_info.data[version_index].quotation_information !== null) {
                    setInfo(trip_info.data[version_index].quotation_information);
                }
                if (trip_info.data[version_index].quotation_brief !== null) {
                    setBrief(trip_info.data[version_index].quotation_brief);
                }
                setCustomInclusions(trip_info.data[version_index].manual_quotation_fields);
                setCustomConditions(trip_info.data[version_index].use_manual_cancel_condition);
            }
        }
    }, []);

    useEffect(() => {
        if (quotation_code !== "marcovasco") {
            for (version_index = 0; version_index < trip_info.data.length; version_index++) {
                if (trip_info.data[version_index].id === trip_displayed_version) {
                    var quotation_default = GetQuotationDefaultInclusions(quotation_code, accommodation_cart, car_cart, flight_cart, poi_cart, transfer_cart, manual_cart, trip_info.data[version_index], trip_currency, currency_list, t, assistance_cart, getTaxesPrice);
                    if (trip_info.data[version_index].quotation_included !== undefined && trip_info.data[version_index].quotation_included !== null) {
                        //convert to formatted string with dash
                        setInclusions("- " + trip_info.data[version_index].quotation_included.join("\n- "));
                    }
                    if (trip_info.data[version_index].quotation_not_included !== undefined && trip_info.data[version_index].quotation_not_included !== null) {
                        //convert to formatted string with dash
                        setNonInclusions("- " + trip_info.data[version_index].quotation_not_included.join("\n- "));
                    }
                    if (['verdie'].includes(quotation_code)) {  
                        if (trip_info.data[version_index].flight_cancel_condition_text !== null) {
                            //convert to formatted string with dash
                            setManualFlightConditions("- " + trip_info.data[version_index].flight_cancel_condition_text.replace("\n", "-").split("-").join("\n- "));
                        }
                        if (trip_info.data[version_index].terrestrial_cancel_condition_text !== null) {
                            //convert to formatted string with dash
                            setManualTerrestrialConditions("- " + trip_info.data[version_index].terrestrial_cancel_condition_text.replace("\n", "-").split("-").join("\n- "));
                        }
                    }
                }
            }
            // convert to formatted string with dash
            let show_flights = false;
            flight_cart.map((flight) => {
                if (flight.is_available && flight.is_displayed && (flight.booking_process_state === "NOTHING" || flight.booking_process_state === "BOOK" || flight.booking_process_state === "UPDATE")){
                    show_flights = true;
                }
            });
            setDefaultInclusions("- " + quotation_default.inclusions.join("\n- "));
            setDefaultNonInclusions("- " + quotation_default.non_inclusions.join("\n- "))
            if (['verdie'].includes(quotation_code)) {
                if (show_flights) {
                    setFlightConditions(quotation_default.flight_conditions);
                }
                setTerrestrialConditions(quotation_default.terrestrial_conditions.join("\n"));
            }
        }
    }, [accommodation_cart, car_cart, flight_cart, poi_cart, transfer_cart, manual_cart, getTaxesPrice]);

    const handleCustomInclusions = () => {
        setCustomInclusions(!customInclusions);
    };

    const handleCustomConditions = () => {
        setCustomConditions(!customConditions);
    };

    const handleInclusions = (new_value) => {
        if (customInclusions) {
            if (new_value.length > inclusions.length + 1) {
                //paste with CTRL V
                setInclusions(new_value);
            } else if ((inclusions.slice(-1) === "\n" && new_value.length > inclusions.length) || inclusions === "") {
                //create new line
                setInclusions(inclusions + "- " + new_value.slice(-1));
            } else if (new_value === "") {
                setInclusions("- ");
            } else {
                setInclusions(new_value);
            }
        } else {
            enqueueSnackbar(t("menu.trip_list.quotation_custom_disabled"), { variant: "warning", disableWindowBlurListener: true, autoHideDuration: 6000 });
        }
    };

    const handleNonInclusions = (new_value) => {
        if (customInclusions) {
            if (new_value.length > nonInclusions.length + 1) {
                //paste with CTRL V
                setNonInclusions(new_value);
            } else if ((nonInclusions.slice(-1) === "\n" && new_value.length > nonInclusions.length) || nonInclusions === "") {
                //create new line
                setNonInclusions(nonInclusions + "- " + new_value.slice(-1));
            } else if (new_value === "") {
                setNonInclusions("- ");
            } else {
                setNonInclusions(new_value);
            }
        } else {
            enqueueSnackbar(t("menu.trip_list.quotation_custom_disabled"), { variant: "warning", disableWindowBlurListener: true, autoHideDuration: 6000 });
        }
    };
    const handleManualFlightConditions = (new_value) => {
        if (customConditions) {
            if (new_value.length > inclusions.length + 1) {
                //paste with CTRL V
                setManualFlightConditions(new_value);
            } else if ((inclusions.slice(-1) === "\n" && new_value.length > inclusions.length) || inclusions === "") {
                //create new line
                setManualFlightConditions(inclusions + "- " + new_value.slice(-1));
            } else if (new_value === "") {
                setManualFlightConditions("- ");
            } else {
                setManualFlightConditions(new_value);
            }
        } else {
            enqueueSnackbar(t("menu.trip_list.cancel_custom_disabled"), { variant: "warning", disableWindowBlurListener: true, autoHideDuration: 6000 });
        }
    };

    const handleManualTerrestrialConditions = (new_value) => {
        if (customConditions) {
            if (new_value.length > nonInclusions.length + 1) {
                //paste with CTRL V
                setManualTerrestrialConditions(new_value);
            } else if ((nonInclusions.slice(-1) === "\n" && new_value.length > nonInclusions.length) || nonInclusions === "") {
                //create new line
                setManualTerrestrialConditions(nonInclusions + "- " + new_value.slice(-1));
            } else if (new_value === "") {
                setManualTerrestrialConditions("- ");
            } else {
                setManualTerrestrialConditions(new_value);
            }
        } else {
            enqueueSnackbar(t("menu.trip_list.cancel_custom_disabled"), { variant: "warning", disableWindowBlurListener: true, autoHideDuration: 6000 });
        }
    };

    const cancelChanges = () => {
        for (version_index = 0; version_index < trip_info.data.length; version_index++) {
            if (trip_info.data[version_index].id === trip_displayed_version) {
                if (trip_info.data[version_index].quotation_information !== null) {
                    setInfo(trip_info.data[version_index].quotation_information);
                } else {
                    setInfo("");
                }
                if (trip_info.data[version_index].quotation_brief !== null) {
                    setBrief(trip_info.data[version_index].quotation_brief);
                } else {
                    setBrief("");
                }
                if (trip_info.data[version_index].quotation_included !== null) {
                    //convert to formatted string with dash
                    setInclusions("- " + trip_info.data[version_index].quotation_included.join("\n- "));
                }
                if (trip_info.data[version_index].quotation_not_included !== null) {
                    //convert to formatted string with dash
                    setNonInclusions("- " + trip_info.data[version_index].quotation_not_included.join("\n -"));
                }
            }
        }
    };

    const confirmQuotation = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            //convert inclusions & non inclusions from string to array
            let request = {
                quotation_information: info !== "" ? info : null,
                quotation_brief: brief !== "" ? brief : null,
                manual_quotation_fields: customInclusions,
                quotation_included: customInclusions ? (inclusions !== "" ? inclusions.replace("- ", "").split("\n- ") : null) : (defaultInclusions !== "" ? defaultInclusions.replace("- ", "").split("\n- ") : null),
                quotation_not_included: customInclusions ? (nonInclusions !== "" ? nonInclusions.replace("- ", "").split("\n- ") : null) : (defaultNonInclusions !== "" ? defaultNonInclusions.replace("- ", "").split("\n- ") : null),
                use_manual_cancel_condition: customConditions,
                terrestrial_cancel_condition_text: customConditions ? (manualTerrestrialConditions !== "" ? manualTerrestrialConditions.replace("-", "").replaceAll("\n-", "\n") : "") : null,
                flight_cancel_condition_text: customConditions ? (manualFlightConditions !== "" ? manualFlightConditions.replace("-", "").replaceAll("\n-", "\n") : "") : null
            };
            axios({
                method: "PATCH",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/`,
                data: request
            }).then(function (response) {
                dispatch(SetTripInfoVersion(response.data));
                enqueueSnackbar(t("menu.trip_list.success_change_quotation"), { variant: "success", disableWindowBlurListener: true, autoHideDuration: 4000 });
            }).catch(function (error) {
                enqueueSnackbar(t("menu.trip_list.failed_change_quotation"), { variant: "error", disableWindowBlurListener: true, autoHideDuration: 4000 });
            });
        }
    };

    const onSignQuotation = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (!trip_info.is_signed && pass_check) {
            let request = [];
            request.push(
                axios({
                    method: "PATCH",
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/`,
                    data: {
                        is_signed: true
                    }
                })
            );
            request.push(
                axios({
                    method: "PATCH",
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/`,
                    data: {
                        is_signed: true
                    }
                })
            );
            axios.all([...request]).then(axios.spread((...responses) => {
                dispatch({
                    type: "MENU_SET_TRIP_INFO",
                    payload: {
                        trip_info: responses[0].data
                    }
                });
                dispatch({
                    type: "TRIP_SET_ALL_DATA",
                    payload: {
                        data: responses[0].data
                    }
                });
                dispatch({
                    type: "TRIP_SET_DATA_TRIP",
                    payload: {
                        data_trip: responses[1].data
                    }
                });
                dispatch({
                    type: "MENU_SET_TRIP_INFO_VERSION",
                    payload: {
                        version: responses[1].data
                    }
                });
            })).catch((error) => {
                console.log('error sign quotation:', error);
            });
        }
    };
    return (
        <Grid style={{ padding: "20px 0px" }}>
            <div className={`${classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${!mobile ? classes.fontSize24 : classes.fontSize20} ${classes.upperCase} ${classes.letterSpacing}`}>
                { t("menu.trip_list.quotation") }
            </div>
            {
                ["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines'].includes(quotation_code) && !trip_info.is_signed &&
                <div style={{ textAlign: 'right', paddingRight: 20 }}>
                    <Button variant={"contained"} onClick={onSignQuotation} className={classes.cancelButton} size={"small"}>{t('menu.trip_list.sign_quotation')}</Button>
                </div>
            }
            {
                !['visiteurs'].includes(quotation_code) &&
                <>
                    <Grid className={classes.genericPadding}>
                        <FormControl variant="outlined" className={classes.formControlRoot1}>
                            <InputLabel id={"info-label"} ref={infoLabel} className={!mobile ? classes.fontSize16 : classes.fontSize12}>
                                {quotation_code === "verdie" ? t("menu.trip_list.quotation_information_verdie") : (quotation_code === "cercledesvoyages" ? t("menu.trip_list.quotation_information_cercle") : quotation_code === 'americavoyages' ? t('menu.trip_list.quotation_information_americavoyages') : t("menu.trip_list.quotation_information")) }
                            </InputLabel>
                            <Tooltip title={t("menu.trip_list.quotation_tooltip")}>
                                <OutlinedInput
                                    name={"info-label"}
                                    id={"info-input"}
                                    labelWidth={infoLabelWidth}
                                    className={classes.circleBorder}
                                    value={info}
                                    onChange={(e) => setInfo(e.target.value)}
                                    inputComponent={"textarea"}
                                    inputProps={{ maxLength: 550 }}
                                    classes={{ input: classes.heightInfo }}
                                />
                            </Tooltip>
                            <span className={`${classes.textRight} ${classes.genericText} ${classes.fontWeight900} ${!mobile ? classes.fontSize16 : classes.fontSize12} ${classes.upperCase} ${classes.letterSpacing}`}> {info.length + "/550" + " " + t("global.characters") } </span>
                        </FormControl>
                    </Grid>
                    <Grid className={!mobile ? classes.genericPadding : ""} style={ !mobile ? {} : { padding: "0px 20px 20px 20px" }}>
                        <FormControl variant="outlined" className={classes.formControlRoot1}>
                            <InputLabel id={"brief-label"} ref={briefLabel} className={!mobile ? classes.fontSize16 : classes.fontSize12}>
                                {quotation_code === "verdie" ? t("menu.trip_list.quotation_brief_verdie") : (quotation_code === "cercledesvoyages" ? t("menu.trip_list.quotation_brief_cercle") :  quotation_code === 'americavoyages' ? t("menu.trip_list.quotation_brief_americavoyages") : t("menu.trip_list.quotation_brief")) }
                            </InputLabel>
                            <Tooltip title={t("menu.trip_list.quotation_tooltip")}>
                                <OutlinedInput
                                    name={"brief-label"}
                                    id={"brief-input"}
                                    labelWidth={briefLabelWidth}
                                    className={classes.circleBorder}
                                    value={brief}
                                    onChange={(e) => setBrief(e.target.value)}
                                    inputComponent={"textarea"}
                                    inputProps={{ maxLength: 1200 }}
                                    classes={ !mobile ? { input: classes.heightBrief } : { input: classes.heightBriefMobile }}
                                />
                            </Tooltip>
                            <span className={`${classes.textRight} ${classes.genericText} ${classes.fontWeight900} ${!mobile ? classes.fontSize16 : classes.fontSize12} ${classes.upperCase} ${classes.letterSpacing}`}> {brief.length + "/1200" + " " + t("global.characters") } </span>
                        </FormControl>
                    </Grid>
                </>
            }
            {
                !['marcovasco'].includes(quotation_code) && (
                // !['marcovasco', 'verdie'].includes(quotation_code) && (
                    <Grid style={{ padding: "0px 20px 20px 20px" }}>
                        <Grid container alignItems="center" justify="space-between" spacing={2} direction={!mobile ? "row" : "column"} className={classes.spacer}>
                            <Grid item xs={mobile ? 12 : 6} style={{ width: "100%" }}>
                                <FormControl variant="outlined" className={classes.formControlRoot1}>
                                    <InputLabel id={"default-included-label"} className={`${!mobile ? classes.fontSize16 : classes.fontSize12} ${classes.greyBackground}`}>
                                        { t("menu.trip_list.default_inclusions")}
                                    </InputLabel>
                                    <OutlinedInput
                                        name={"default-included-label"}
                                        id={"default-included-input"}
                                        labelWidth={230}
                                        className={`${classes.circleBorder} ${classes.greyBackground}`}
                                        disabled={true}
                                        value={defaultInclusions}
                                        inputComponent={"textarea"}
                                        classes={ !mobile ? { input: classes.heightInclusion } : { input: classes.heightInclusionMobile }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={mobile ? 12 : 6} style={{ width: "100%" }}>
                                <FormControl variant="outlined" className={classes.formControlRoot1}>
                                    <InputLabel id={"default-not-included-label"} className={`${!mobile ? classes.fontSize16 : classes.fontSize12} ${classes.greyBackground}`}>
                                        { t("menu.trip_list.default_non_inclusions")}
                                    </InputLabel>
                                    <OutlinedInput
                                        name={"default-not-included-label"}
                                        id={"default-not-included-input"}
                                        labelWidth={265}
                                        className={`${classes.circleBorder} ${classes.greyBackground}`}
                                        disabled={true}
                                        value={defaultNonInclusions}
                                        inputComponent={"textarea"}
                                        classes={ !mobile ? { input: classes.heightInclusion } : { input: classes.heightInclusionMobile }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" justify="space-between" spacing={2} direction={!mobile ? "row" : "column"} className={classes.spacer}>
                            <Grid item xs={mobile ? 12 : 6} style={{ width: "100%" }}>
                                <FormControl variant="outlined" className={classes.formControlRoot1}>
                                    <InputLabel id={"included-label"} className={!mobile ? classes.fontSize16 : classes.fontSize12}>
                                        { t("menu.trip_list.quotation_included")}
                                    </InputLabel>
                                    <OutlinedInput
                                        name={"included-label"}
                                        id={"included-input"}
                                        labelWidth={190}
                                        className={classes.circleBorder}
                                        value={inclusions}
                                        onChange={(e) => handleInclusions(e.target.value)}
                                        inputComponent={"textarea"}
                                        // disabled={!customInclusions}
                                        classes={ !mobile ? { input: classes.heightInclusion } : { input: classes.heightInclusionMobile }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={mobile ? 12 : 6} style={{ width: "100%" }}>
                                <FormControl variant="outlined" className={classes.formControlRoot1}>
                                    <InputLabel id={"not-included-label"} className={!mobile ? classes.fontSize16 : classes.fontSize12}>
                                        { t("menu.trip_list.quotation_not_included")}
                                    </InputLabel>
                                    <OutlinedInput
                                        name={"not-included-label"}
                                        id={"not-included-input"}
                                        labelWidth={230}
                                        className={classes.circleBorder}
                                        value={nonInclusions}
                                        onChange={(e) => handleNonInclusions(e.target.value)}
                                        inputComponent={"textarea"}
                                        // disabled={!customInclusions}
                                        classes={ !mobile ? { input: classes.heightInclusion } : { input: classes.heightInclusionMobile }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid className={`${classes.textRight} ${classes.spacer}`}>
                            <FormControlLabel
                                control={ <Switch checked={customInclusions} color="primary" onChange={handleCustomInclusions}/> }
                                label={ t("menu.trip_list.quotation_custom_inclusions") }
                                classes={{ label: `${classes.genericText} ${classes.fontWeight900} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${classes.upperCase}` }}
                                className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12}`}
                            />
                        </Grid>
                    </Grid>
                )
            }
            {
                ['verdie'].includes(quotation_code) && (
                    <Grid style={{ padding: "0px 20px 20px 20px" }}>
                        <Grid container alignItems="center" justify="space-between" spacing={2} direction={!mobile ? "row" : "column"} className={classes.spacer}>
                            <Grid item xs={ 12 }>
                                <div className={`${classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${!mobile ? classes.fontSize24 : classes.fontSize20} ${classes.upperCase} ${classes.letterSpacing}`}>{'Conditions d’annulation client'}</div>
                            </Grid>
                            {/* <Grid item xs={mobile ? 12 : 6} style={{ width: "100%"}}>
                                <FormControl variant="outlined" className={classes.formControlRoot1}>
                                    <InputLabel id={"auto-flight-label"} className={`${!mobile ? classes.fontSize16 : classes.fontSize12} ${classes.greyBackground}`}>
                                        { t("menu.trip_list.auto_flight")}
                                    </InputLabel>
                                        <OutlinedInput
                                            name={"auto-flight-label"}
                                            id={"auto-flight-input"}
                                            labelWidth={230}
                                            className={`${classes.circleBorder} ${classes.greyBackground}`}
                                            disabled={true}
                                            value={flightConditions}
                                            inputComponent={"textarea"}
                                            classes={ !mobile ? { input: classes.heightInclusion } : { input: classes.heightInclusionMobile }}
                                        />
                                </FormControl>
                            </Grid> */}
                            <Grid item xs={12} style={{ width: "100%"}}>
                                <FormControl variant="outlined" className={classes.formControlRoot1}>
                                    <InputLabel id={"auto-terrestrial-label"} className={`${!mobile ? classes.fontSize16 : classes.fontSize12} ${classes.greyBackground}`}>
                                        { t("menu.trip_list.auto_terrestrial")}
                                    </InputLabel>
                                        <OutlinedInput
                                            name={"auto-terrestrial-label"}
                                            id={"auto-terrestrial-input"}
                                            labelWidth={265}
                                            className={`${classes.circleBorder} ${classes.greyBackground}`}
                                            disabled={true}
                                            value={terrestrialConditions}
                                            inputComponent={"textarea"}
                                            classes={ !mobile ? { input: classes.heightInclusion } : { input: classes.heightInclusionMobile }}
                                        />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" justify="space-between" spacing={2} direction={!mobile ? "row" : "column"} className={classes.spacer}>
                            {/* <Grid item xs={mobile ? 12 : 6} style={{ width: "100%"}}>
                                <FormControl variant="outlined" className={classes.formControlRoot1}>
                                    <InputLabel id={"manual-flight-label"} className={!mobile ? classes.fontSize16 : classes.fontSize12}>
                                        { t("menu.trip_list.manual_flight")}
                                    </InputLabel>
                                        <OutlinedInput
                                            name={"manual-flight-label"}
                                            id={"manual-flight-input"}
                                            labelWidth={190}
                                            className={classes.circleBorder}
                                            value={manualFlightConditions}
                                            onChange={(e) => handleManualFlightConditions(e.target.value)}
                                            inputComponent={"textarea"}
                                            // disabled={!customConditions}
                                            classes={ !mobile ? { input: classes.heightInclusion } : { input: classes.heightInclusionMobile }}
                                        />
                                </FormControl>
                            </Grid> */}
                            <Grid item xs={12} style={{ width: "100%"}}>
                                <FormControl variant="outlined" className={classes.formControlRoot1}>
                                    <InputLabel id={"manual-terrestrial-label"} className={!mobile ? classes.fontSize16 : classes.fontSize12}>
                                        { t("menu.trip_list.manual_terrestrial")}
                                    </InputLabel>
                                        <OutlinedInput
                                            name={"manual-terrestrial-label"}
                                            id={"manual-terrestrial-input"}
                                            labelWidth={230}
                                            className={classes.circleBorder}
                                            value={manualTerrestrialConditions}
                                            onChange={(e) => handleManualTerrestrialConditions(e.target.value)}
                                            inputComponent={"textarea"}
                                            // disabled={!customConditions}
                                            classes={ !mobile ? { input: classes.heightInclusion } : { input: classes.heightInclusionMobile }}
                                        />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid className={`${classes.textRight} ${classes.spacer}`}>
                            <FormControlLabel
                                control={ <Switch checked={customConditions} color="primary" onChange={handleCustomConditions}/> }
                                label={ t("menu.trip_list.quotation_custom_conditions") }
                                classes={{ label: `${classes.genericText} ${classes.fontWeight900} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${classes.upperCase}` }}
                                className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12}`}
                            />
                        </Grid>
                    </Grid>
                )
            }
            <Grid container alignItems="center" justify={!mobile ? "flex-end" : "center" } style={ !mobile ? { paddingRight: 20 } : {}}>
                <Grid item>
                    <Button variant="contained" className={classes.cancelButton} onClick={cancelChanges} size="small">
                        {t("global.cancel")}
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" className={classes.validateButton} onClick={confirmQuotation} size="small">
                        {t("global.validate")}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
});

export default React.memo(TripListQuotation);
