import GetCookie from "../../Common/Functions/GetCookie";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";

import { SetGroupIndex, SetManualJourney } from "../../../Actions/Flight";
import { SetState, SetManualInfo } from "../../../Actions/FlightSearch";

import CountAgePaxType from "./CountAgePaxType";
import CheckResponse from "./CheckResponse";
import GetCancelPenalty from "./GetCancelPenalty";
import GetChangePenalty from "./GetChangePenalty";

import axios from "axios";
import moment from "moment";

export default (manual_request, flight_groups, flight_providers, group_index, dispatch, enqueueSnackbar, end_date, t, setManualSearching, router) => {
    {
        dispatch(SetGroupIndex(group_index));
        dispatch(SetState());
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            let group = flight_groups[0]
            let pax_passenger = CountAgePaxType(group.travelers);
            let url = `${API_HREF}client/${window.id_owner}/flights/availability/?trip=${GetCookie("trip_id")}&provider_id=${group.provider[0]}`;
            if (group.public_price || group.private_price) {
                if (group.private_price && !group.public_price) {
                    if (flight_providers.find(item => item.id === group.provider[0]).code === "1G"){
                        url += "&pax_type=ITX:" + pax_passenger.ADT + ",INN:" + pax_passenger.CNN + ",INF:" + pax_passenger.INF + "&fare_type=private";
                    }else{
                        url += "&pax_type=ADT:" + pax_passenger.ADT + ",CNN:" + pax_passenger.CNN + ",INF:" + pax_passenger.INF + "&fare_type=private";
                    }
                }
                if (!group.private_price && group.public_price) {
                    url += "&pax_type=ADT:" + pax_passenger.ADT + ",CNN:" + pax_passenger.CNN + ",INF:" + pax_passenger.INF + "&fare_type=public";
                }
                if (group.private_price && group.public_price) {
                    url += "&pax_type=ADT:" + pax_passenger.ADT + ",CNN:" + pax_passenger.CNN + ",INF:" + pax_passenger.INF + "&fare_type=all";
                }
            }
            else {
                url += "&pax_type=ADT:" + pax_passenger.ADT + ",CNN:" + pax_passenger.CNN + ",INF:" + pax_passenger.INF + "&fare_type=all";
            }
            if (group.manual_luggage !== null){
                url += `&nb_baggage=${group.manual_luggage ? 1 : 0}`;
            }
            let request = {
                data: typeof manual_request === "string" ? manual_request : manual_request[group_index]
            };
            axios({
                method: "POST",
                url: url,
                data: JSON.stringify(request),
                headers: headers
            }).then(function(response){
                let results = [];
                results.push(response.data);
                let arr_flights = CheckResponse(results, end_date);
                let flight = arr_flights[0];
                let total_price = 0;
                let pax_pricing = flight.fares[0].pax_pricing;
                let adult = null;
                let children = null;
                let baby = null;
                let total_cancel_penalty = null;
                let flight_stop_over = 0;
                let journey = [];
                let airlines = [];
                let trace_id = response.data.trace_id;
                let last_ticketing = response.data.fares[0].latest_ticketing;
                for (let [key, value] of Object.entries(flight.airlines)) {
                    value.code = value.airline_code;
                    value.name = value.commercial_name;
                    value.displayed = false;
                    value.price = 0;
                    delete value.alliance;
                    delete value.airline_code;
                    delete value.commercial_name;
                    airlines.push(value);
                }
                let airports = Object.assign({}, flight.airports);
                Object.keys(airports).map((key) => {
                    airports[key].code = airports[key].airport_code;
                });
                let stations = Object.assign({}, flight.stations)
                Object.keys(stations).map((key) => {
                    stations[key].code = stations[key].station_code;
                });
                flight.outbounds.map((segment) => {
                    segment.legs.map((leg) => {
                        leg.seats = {};
                        leg.paid_options = [];
                        leg.flight_details = {
                            meals: [],
                            terminal: {
                                origin: "",
                                destination: ""
                            }
                        };
                    });
                    let last_leg = segment.legs.length - 1;
                    let origin_city = segment.legs[0].origin_airport !== undefined ? airports[segment.legs[0].origin_airport].iata_city.international_name : stations[segment.legs[0].origin_station].iata_city.international_name;
                    let dest_city = segment.legs[last_leg].destination_airport ? airports[segment.legs[last_leg].destination_airport].iata_city.international_name : stations[segment.legs[last_leg].destination_station].iata_city.international_name;
                    let origin_airport = segment.legs[0].origin_airport !== undefined ? airports[segment.legs[0].origin_airport].name : stations[segment.legs[0].origin_station].name;
                    let dest_airport = segment.legs[last_leg].destination_airport !== undefined ? airports[segment.legs[last_leg].destination_airport].name : stations[segment.legs[last_leg].destination_station].name;
                    let origin_airport_code = segment.legs[0].origin_airport !== undefined ? airports[segment.legs[0].origin_airport].airport_code : stations[segment.legs[0].origin_station].station_code;
                    let dest_airport_code = segment.legs[last_leg].destination_airport !== undefined ? airports[segment.legs[last_leg].destination_airport].airport_code : stations[segment.legs[last_leg].destination_station].station_code;
                    journey.push({
                        start_date: moment.utc(segment.legs[0].departure_time),
                        origin: {
                            name: origin_airport,
                            airport_code: origin_airport_code,
                            city: origin_city
                        },
                        destination: {
                            name: dest_airport,
                            airport_code: dest_airport_code,
                            city: dest_city
                        },
                        class_type: 0
                    });
                    if (flight_stop_over < segment.legs.length) {
                        flight_stop_over = segment.legs.length;
                    }
                });
                for (let j = 0; j < pax_pricing.length; j++) {
                    if (pax_pricing[j].pax_code === "ADT" || pax_pricing[j].pax_code === "ITX") {
                        adult = {
                            cancel_penalty: GetCancelPenalty(pax_pricing[j]),
                            change_penalty: GetChangePenalty(pax_pricing[j]),
                            margin: pax_pricing[j].margin,
                            taxes: pax_pricing[j].taxes,
                            total_price: pax_pricing[j].prices[0].selling_price,
                            number: pax_passenger.ADT
                        };
                    } else if (pax_pricing[j].pax_code === "CNN" || pax_pricing[j].pax_code === "INN") {
                        children = {
                            cancel_penalty: GetCancelPenalty(pax_pricing[j]),
                            change_penalty: GetChangePenalty(pax_pricing[j]),
                            margin: pax_pricing[j].margin,
                            taxes: pax_pricing[j].taxes,
                            total_price: pax_pricing[j].prices[0].selling_price,
                            number: pax_passenger.CNN
                        };
                    } else if (pax_pricing[j].pax_code === "INF") {
                        baby = {
                            cancel_penalty: GetCancelPenalty(pax_pricing[j]),
                            change_penalty: GetChangePenalty(pax_pricing[j]),
                            margin: pax_pricing[j].margin,
                            taxes: pax_pricing[j].taxes,
                            total_price: pax_pricing[j].prices[0].selling_price,
                            number: pax_passenger.INF
                        };
                    }
                    total_price = (adult !== null ? (adult.number * (parseFloat(adult.total_price) + parseFloat(adult.taxes[0].selling_price))) : 0) + (children !== null ? (children.number * (parseFloat(children.total_price) + parseFloat(children.taxes[0].selling_price))) : 0) + (baby !== null ? (baby.number * (parseFloat(baby.total_price) + parseFloat(baby.taxes[0].selling_price))) : 0);
                    if (adult !== null) {
                        if (adult.cancel_penalty !== null) {
                            total_cancel_penalty += adult.number * adult.cancel_penalty;
                        }
                        else {
                            total_cancel_penalty = null;
                        }
                    }
                    if (children !== null) {
                        if (children.cancel_penalty !== null) {
                            total_cancel_penalty += children.number * children.cancel_penalty;
                        }
                        else {
                            total_cancel_penalty = null;
                        }
                    }
                    if (baby !== null) {
                        if (baby.cancel_penalty !== null) {
                            total_cancel_penalty += baby.number * baby.cancel_penalty;
                        }
                        else {
                            total_cancel_penalty = null;
                        }
                    }
                }
                let refundable = total_cancel_penalty !== null ? (total_cancel_penalty < parseFloat(total_price)) : null;
                let flight_info = [];
                let options = Object.assign({}, response.data);
                options.segments = options.segments.reduce(function (r, a) {
                    r[a.group] = r[a.group] || [];
                    r[a.group].push(a);
                    return r;
                }, []);
                flight_info.push({
                    adult: adult,
                    children: children,
                    baby: baby,
                    outbounds: flight.outbounds,
                    refundable: refundable,
                    provider_id: group.provider[0],
                    total_price: total_price.toFixed(2),
                    flight_stop_over: flight_stop_over,
                    id: 0,
                    options: options,
                    error_options: null,
                    detail_price: false,
                    trace_id: trace_id
                });
                dispatch(SetManualJourney(group_index, journey));
                dispatch(SetManualInfo(airlines, airports, flight_info, trace_id, last_ticketing, stations));
                router.push(`/${window.url_name}/apps/selected-flight`);
        }).catch(function(error){
            console.log("manual_request error:", error);
            console.log("manual_request error:", error.response);
            setManualSearching(false);
            let responseJSON = error.response;
            if (responseJSON.data === "date_too_far") {
                enqueueSnackbar(t("flight.date_too_far_error"), { variant: "error" });
            }else if (responseJSON.data.errors !== undefined){
                enqueueSnackbar(t("flight.not_available_error"), { variant: "error" });
            }else {
                enqueueSnackbar(responseJSON.data, { variant: "error" })
            }
        })
        }
    }
}