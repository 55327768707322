import { useSnackbar } from "notistack";
import axios from "axios";
import clsx from "clsx";
import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import GetPricesId from "../Cart/Functions/Margin/GetPricesId.js";
import CheckBeforeRequest from "../Common/CheckBeforeRequest.js";
import GetCookie from "../Common/Functions/GetCookie";
import SetCookie from "../Common/Functions/SetCookie.js";
import { ToShowVersion } from "../../Actions/Menu.js";
import { SetFlightCart } from "../../Actions/Flight.js";
import { SetCarsCart } from "../../Actions/CarsSearch.js";
import { SetCart, SetSortCart } from "../../Actions/Accommodation.js";
import { SetManualCart } from "../../Actions/Cart.js";
import { SetTransfersCart } from "../../Actions/Transfers.js";
import { ToggleAndSetLanguage } from "../../Actions/Header.js";
import { LockItineraryActions } from "../../Actions/Base.js";
import GetTrip from "../Base/Functions/GetTrip.js";

const useStyles = makeStyles(theme => ({
    formControlRoot: {
        borderRadius: "20px",
        padding: 0,
        position: "relative",
        textTransform: "capitalize",
        display: "block",
        border: 'none',
        boxShadow: 'none'
        // boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)'
  	},
    inputStepper: {
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: 12
        },
        "& .mui-jss-MuiOutlinedInput-root": {
            position: "relative",
            borderRadius: 20
        }
    },
    paperStepper: {
        position: "absolute !important",
        top: "100% !important",
        zIndex: "1 !important",
        background: "rgb(255, 255, 255) !important",
        borderRadius: "32px !important",
        boxShadow: "rgb(0 0 0 / 20%) 0px 6px 20px !important",
        marginTop: "12px !important",
        maxHeight: "calc(100vh - 220px) !important",
        overflow: "hidden auto !important",
        padding: "16px 16px !important"
    },
    orangeButton: {
        backgroundColor: "#E6592F",
        color: "white"
    },
    arrowDown: {
        margin: '6px 5px',
        position: 'absolute',
        right: 0,
        top: 0
    },
    buttonWhite: {
	    backgroundColor: 'white'
    },
    responsiveSize: {
        fontSize: 10
    }
}));

const HeaderTripName = ({setIsOpen, isOpen, router}) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const theme = useTheme();
    let current_location = router.getCurrentLocation();
	const smallScreen = useMediaQuery(theme.breakpoints.between(960, 1258));

    const data_trip = useSelector((store) => store.trip.data_trip);
    const trip_info = useSelector((store) => store.trip.all_data);
    const to_show_version = useSelector(store => store.menu.to_show_version);
    const itinerary_type_details_data = useSelector(store => store.itinerary_type.itinerary_type_details_data);
    const user = useSelector(store => store.user.user);

    const [trip_displayed_version, setVersion] = useState(to_show_version !== null ? to_show_version : `${GetCookie('trip_id_version')}`);

    useEffect(() => {
        if (to_show_version !== null && itinerary_type_details_data !== null && itinerary_type_details_data.type !== undefined && !window.location.pathname.includes('trip-info')) {
            setInfo(false);
        }
    }, [to_show_version]);

    const handleOpen = () => {
        if (isOpen === null || isOpen !== "tripName") {
            setIsOpen("tripName");
        } else {
            setIsOpen(null);
        }
    };
    const setInfo = (flag) => {
        let user_token = GetCookie("token");
        let id_user = GetCookie("id_user");
        let client_user = GetCookie("client_user");
        const crmCookies = {
            crm_token: GetCookie("crm_token"),
            crm_id_user: GetCookie("crm_id_user"),
            crm_client_user: GetCookie("crm_client_user"),
            isFromCrm: GetCookie("isFromCrm")
        };
        //Delete the cookie
        let cookies = document.cookie.split("; ");
        for (let c = 0; c < cookies.length; c++) {
            let d = window.location.hostname.split(".");
            while (d.length > 0) {
                let cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" + d.join(".") + " ;path=";
                let p = location.pathname.split("/");
                document.cookie = cookieBase + "/";
                while (p.length > 0) {
                    document.cookie = cookieBase + p.join("/");
                    p.pop();
                }
                d.shift();
            }
        }
        SetCookie("trip_id", trip_info.id, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
        SetCookie("trip_id_version", trip_displayed_version, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
        SetCookie("trip_token", trip_info.token, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
        SetCookie("token", user_token, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
        SetCookie("id_user", id_user, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
        SetCookie("client_user", client_user, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
        if (crmCookies.crm_token) {
            for (const key in crmCookies) {
                SetCookie(key, crmCookies[key], 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
            }
        }
        if (flag) {
            dispatch({ type: 'SMALL_FLUSH' });
        } else {
            dispatch(SetFlightCart([]));
            dispatch(SetCarsCart([]));
            dispatch(SetCart([]));
            dispatch(SetSortCart([]));
            dispatch(SetManualCart([]));
            dispatch(SetTransfersCart([]));
        }
        dispatch(ToggleAndSetLanguage(false, i18n.language));
        dispatch(LockItineraryActions());
        GetTrip(user, dispatch, t);
    };
    const handleVersion = (event, version) => {
        setVersion(version);
    	//SetCookie("trip_id_version", version, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            axios({
                method: "GET",
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${version}/`,
                headers: headers
            }).then(function (response) {
                dispatch(ToShowVersion(version));
                dispatch({
                    type: "TRIP_SET_DATA_TRIP",
                    payload: {
                        data_trip: response.data
                    }
                });
                handleOpen();
            }).catch(function (error) {
                console.log(error.response);
            });
        }
    };
    if (data_trip !== null && !data_trip.trip.itinerary_type && !data_trip.trip.package_type && !data_trip.trip.circuit_type) {
        return (
            <Fragment>
                <Button className={clsx(classes.formControlRoot, classes.buttonWhite, { [classes.responsiveSize]: smallScreen })} variant={"outlined"} fullWidth disableRipple={true} onClick={handleOpen}>
                    {
                        data_trip !== null && data_trip.contact_traveler_detail !== null && (
                            <Fragment>
                                <div>{`${data_trip.contact_traveler_detail.first_name} ${data_trip.contact_traveler_detail.last_name}`}</div>
                                {
                                    trip_info.name !== null &&
                                    <Tooltip title={trip_info.name}>
                                        <div style={{ width: "80%", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", margin: "auto" }}>{trip_info.name}</div>
                                    </Tooltip>
                                }
                            </Fragment>
                        )
                    }
                    {
                        data_trip.trip !== undefined && (data_trip.trip.itinerary_type || data_trip.trip.package_type || data_trip.trip.circuit_type) &&
                        <div>{`${data_trip.name}`}</div>
                    }
                </Button>

            </Fragment>
        );
    }
    return (
        <Fragment>
            <Button className={clsx(classes.formControlRoot, classes.buttonWhite, { [classes.responsiveSize]: smallScreen })} variant={"outlined"} fullWidth disableRipple={true} onClick={handleOpen}>
            {
                (data_trip.trip.itinerary_type || data_trip.trip.package_type || data_trip.trip.circuit_type) &&
                    <Fragment>
                        <div>{`${data_trip.name}`}</div>
                    </Fragment>

                }
            </Button>
            {
                isOpen === "budget" && data_trip !== null && (data_trip.trip.itinerary_type || data_trip.trip.package_type || data_trip.trip.circuit_type) && trip_info.data.length > 1 && (
                    <ClickAwayListener touchEvent={ false } onClickAway={handleOpen}>
                        <Paper
                            className={classes.paperStepper}
                            elevation={5}
                            children={
                                <Fragment>
                                    <DialogContent>
                                        <List>
                                            {
                                                trip_info.data.map((version, version_index) => {
                                                    let version_new_index = version_index + 1;
                                                    return (
                                                        <ListItem button key={"version-id-" + version.id} onClick={(e) => handleVersion(e, version.id)} selected={parseInt(trip_displayed_version) === version.id}>
                                                            <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}`}>
                                                                { t("menu.trip_list.nb_version") + version_new_index}
                                                                { version.name !== null && (" (" + version.name + ") " + version.id)}
                                                            </Grid>
                                                        </ListItem>
                                                    );
                                                })
                                            }
                                        </List>
                                    </DialogContent>
                                </Fragment>
                            }
                        />
                    </ClickAwayListener>
                )
            }
        </Fragment>
    );
};
export default React.memo(HeaderTripName);
