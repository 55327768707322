import axios from "axios";
import { useSelector } from "react-redux";
import GetCookie from "../../../Common/Functions/GetCookie";
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";
import { Mail } from "../objects/mail";
import { AppState } from "../../../../Reducers/Reducers";

type Result = (mail: Mail, checkMinisiteTag?: boolean, isContract?: boolean) => Promise<void>

type Options = {
    onTrigger: () => void,
    onSuccess: () => void,
    onError: (error: Error) => void,
    onFinally: () => void,
}

type RequestOptions = {
    tripId: number,
    version: number,
    mail: Mail,
    checkMinisiteTag: boolean,
    isContract?: boolean,
}

export function useSendQuotation(options: Options): Result {
    const trip_info = useSelector((store: AppState) => store.menu.trip_info);
    const to_show_version = useSelector((store: AppState) => store.menu.to_show_version);
    const version = to_show_version !== null ? to_show_version : trip_info?.current_version;
    return async (mail, checkMinisiteTag, isContract) => {
        if (trip_info && version) {
            try {
                options.onTrigger();
                await makeRequest({
                    tripId: trip_info.id,
                    version,
                    mail,
                    checkMinisiteTag: !!checkMinisiteTag,
                    isContract
                });
                options.onSuccess();
            } catch (error: any) {
                options.onError(error);
            } finally {
                options.onFinally();
            }
        }
    };
}

async function makeRequest(options: RequestOptions): Promise<void> {
    let { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        const form = new FormData();
        form.append('subject', options.mail.object);
        form.append('email_content', options.mail.content);
        form.append('recipient', options.mail.to);
        form.append('sender_email', options.mail.from.email);
        form.append('sender_name', options.mail.from.name.normalize("NFD").replace(/\p{Diacritic}/gu, ""));
        form.append('ccs', options.mail.cc.join(','));
        form.append('check_minisite_tag', options.checkMinisiteTag ? 'true' : 'false');
        // if (options.isContract) {
        //     form.append('send_as_attached_file', 'true');
        // }

        await axios.post(
            //eslint-disable-next-line no-undef
            `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/send_quotation/`,
            form,
            {
                headers: {
                    ...headers,
                    'Content-Type': undefined
                }
            }
        );
    }
}
