import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Autocomplete,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import { LocationOn } from "@mui/icons-material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import axios, { AxiosResponse, CancelTokenSource } from "axios";
import { Moment } from "moment-timezone";
import { debounce, isString } from "lodash";
import { CartConstructionReplaceProductContext } from "./utils/cartConstructionReplaceProductContext";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import { FlashDestination } from "../Itinerary/objects/flashDestination";
import { AppState } from "../../Reducers/Reducers";

type SearchInputs = {
    startDate: Moment | null,
    endDate: Moment | null,
    pickupPlace: FlashDestination | null,
    returnPlace: FlashDestination | null,
    samePlace: boolean,
    age: number,
    count: number,
    contract: string
}

type Props = {
    value: SearchInputs,
    onChange: React.Dispatch<React.SetStateAction<SearchInputs>>
}

export function CartConstructionReplaceCarInputs(props: Props): JSX.Element | null {
    function onChange<K extends keyof SearchInputs>(
        key: K,
        value: SearchInputs[K]
    ): void {
        props.onChange((state) => ({
            ...state,
            [key]: value
        }));
    }

    return (
        <Inputs
            inputs={props.value}
            onChange={onChange}
        />
    );
}

type InputsProps = {
    inputs: SearchInputs,
    onChange: <K extends keyof SearchInputs>(key: K, value: SearchInputs[K]) => void,
}

const Inputs = (props: InputsProps) => {
    const { t } = useTranslation();
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const providers = useSelector((state: AppState) => state.trip.providers);
    const context = useContext(CartConstructionReplaceProductContext);
    const [departureOptions, setDepartureOptions] = useState([]);
    const [returnOptions, setReturnOptions] = useState([]);
    const { headers } = CheckBeforeRequest();
    const quotation_code = JSON.parse(localStorage.getItem("config") ?? '{}').quotation_code;

    useEffect(() => {
        if (
            !props.inputs.pickupPlace ||
            !props.inputs.returnPlace ||
            !props.inputs.startDate ||
            !props.inputs.endDate ||
            (
                props.inputs.startDate.isSame(props.inputs.endDate, 'day') &&
                props.inputs.startDate.get('hour') === props.inputs.endDate.get('hour')
            )
        ) {
            context.onHideNextButton();
        } else {
            context.onShowNextButton();
        }
    }, [
        context,
        props.inputs.pickupPlace,
        props.inputs.returnPlace,
        props.inputs.startDate,
        props.inputs.endDate
    ]);

    useEffect(() => {
        if (context.mode === 'add' && context.contentItem) {
            const startDate = window.moment.utc(context.contentItem.from);
            const endDate = window.moment.utc(context.contentItem.to);
            props.onChange('startDate', startDate);
            props.onChange(
                'endDate',
                startDate.isSame(endDate, 'day') ?
                    startDate.clone().add(1, 'hour') :
                    endDate
            );
        }
    }, [
        context.mode,
        context.contentItem?.from,
        context.contentItem?.to
    ]);

    useEffect(() => {
        if (
            context.mode === 'add' &&
            props.inputs.startDate &&
            props.inputs.endDate?.isBefore(props.inputs.startDate)
        ) {
            props.onChange('endDate', props.inputs.startDate);
        }
    }, [
        context.mode,
        props.inputs.startDate,
        props.inputs.endDate
    ]);

    useEffect(() => {
        if (props.inputs.samePlace) {
            props.onChange('returnPlace', props.inputs.pickupPlace);
        }
    }, [
        props.inputs.pickupPlace,
        props.inputs.samePlace
    ]);
    useEffect(() => {
        const providerId = providers.find((provider) => provider.provider.code === 'hertz_gsa')?.provider.id;
        if (providerId && (props.inputs.pickupPlace || props.inputs.returnPlace)) {
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/cars/get_locations/`,
                params: {
                    provider_id: providerId,
                    destination_id_pickup: props.inputs.pickupPlace ? props.inputs.pickupPlace.destination_id : undefined,
                    destination_id_return: props.inputs.returnPlace ? props.inputs.returnPlace.destination_id : undefined,
                },
            }).then(function (response) {
                setDepartureOptions(response.data[0].pickup_locations);
                setReturnOptions(response.data[0].return_locations);
            }).catch(function (error) {
                if (!axios.isCancel(error)) {
                    console.log('Request canceled', error.message);
                }
                //TODO: show snackbar error
            });
        }
    }, [props.inputs.pickupPlace, props.inputs.returnPlace]);
    return (
        <>
            <Typography sx={{ marginBottom: 1.5 }}>
                {t('cart-material.cart-construction-dates-dot')}
            </Typography>
            <Stack direction="row" spacing={1} sx={{ marginBottom: 1.5 }}>
                <LocalizationProvider dateAdapter={ AdapterMoment } locale={language}>
                    <DateTimePicker
                        label={t('cart-material.cart-construction-start-date')}
                        value={props.inputs.startDate}
                        onChange={(value) => props.onChange('startDate', value)}
                        sx={{ width: '100%' }}
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={ AdapterMoment } locale={language}>
                    <DateTimePicker
                        label={t('cart-material.cart-construction-end-date')}
                        value={props.inputs.endDate}
                        minDate={props.inputs.startDate ?? undefined}
                        onChange={(value) => props.onChange('endDate', value)}
                        sx={{ width: '100%' }}
                    />
                </LocalizationProvider>
            </Stack>
            <Typography>
                {t('cart-material.cart-construction-places-dot')}
            </Typography>
            <FormControlLabel
                control={<Checkbox checked={props.inputs.samePlace} />}
                onChange={(_, checked) => props.onChange('samePlace', checked)}
                label={t('cars.different_location')}
            />
            <Stack direction="row" spacing={1} sx={{ marginBottom: 1.5 }}>
                <PlacePicker
                    label={t('cart-material.cart-construction-pickup')}
                    value={props.inputs.pickupPlace}
                    onChange={(value) => props.onChange('pickupPlace', value)}
                />
                {
                    !props.inputs.samePlace &&
                    <PlacePicker
                        label={t('cart-material.cart-construction-restitution')}
                        value={props.inputs.returnPlace}
                        onChange={(value) => props.onChange('returnPlace', value)}
                    />
                }
            </Stack>
            {
                ["volonline", "touristforum"].includes(quotation_code) &&
                <Stack direction="row" spacing={1} sx={{ marginBottom: 1.5 }}>
                    <FormControl fullWidth>
                        <InputLabel shrink>
                            { t("cart-material.station-pickup") }
                        </InputLabel>
                        <Select
                            label={ t("cart-material.station-pickup") }
                            notched
                            value={ props.inputs.origin_station !== null ? (props.inputs.origin_station.oag_code + props.inputs.origin_station.vendor_code) : "" }
                            onChange={ (event) => {
                                const origin_station = departureOptions.find((option) => (option.oag_code + option.vendor_code) === event.target.value)
                                props.onChange('origin_station', origin_station)
                            } }
                        >
                            {
                                departureOptions.map((option) => (
                                    <MenuItem value={ option.oag_code + option.vendor_code }>
                                        { option.name }
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    {
                        !props.inputs.samePlace &&
                        <FormControl fullWidth>
                            <InputLabel shrink>
                                { t("cart-material.station-pickup") }
                            </InputLabel>
                            <Select
                                label={ t("cart-material.station-pickup") }
                                notched
                                value={ props.inputs.return_station !== null ? (props.inputs.return_station.oag_code + props.inputs.return_station.vendor_code) : "" }
                                onChange={ (event) => {
                                    const return_station = returnOptions.find((option) => (option.oag_code + option.vendor_code) === event.target.value)
                                    props.onChange('return_station', return_station)
                                } }
                            >
                                {
                                    returnOptions.map((option) => (
                                        <MenuItem value={ option.oag_code + option.vendor_code }>
                                            { option.name }
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    }
                </Stack>
            }
            <Typography sx={{ marginBottom: 1.5 }}>
                {t('cart-material.cart-construction-car-dot')}
            </Typography>
            <Stack direction="row" spacing={1}>
                <TextField
                    type="number"
                    label={t('cars.driver_age')}
                    value={props.inputs.age}
                    onChange={(event) => props.onChange('age', parseInt(event.target.value))}
                    fullWidth
                />
                <TextField
                    type="number"
                    label={t('cars.cars_nbr')}
                    value={props.inputs.count}
                    onChange={(event) => props.onChange('count', parseInt(event.target.value))}
                    fullWidth
                />
                <FormControl fullWidth>
                    <InputLabel>
                        {t("cars.optional_contract")}
                    </InputLabel>
                    <Select
                        label={t("cars.optional_contract")}
                        value={props.inputs.contract}
                        onChange={(event) => props.onChange('contract', event.target.value)}
                    >
	      		      	<MenuItem value={""}>-</MenuItem>
	      		      	<MenuItem value={"FULLY"}>{t("cars.contract_fully")}</MenuItem>
	      		      	<MenuItem value={"FULLY GPS"}>{t("cars.contract_fully_gps")}</MenuItem>
	      		      	<MenuItem value={"SUPER FULLY"}>{t("cars.contract_super_fully")}</MenuItem>
	      		      	<MenuItem value={"SUPER FULLY GPS"}>{t("cars.contract_super_fully_gps")}</MenuItem>
	      		      	<MenuItem value={"GOLD"}>{t("cars.contract_gold")}</MenuItem>
	      		      	<MenuItem value={"GOLD GPS"}>{t("cars.contract_gold_gps")}</MenuItem>
                    </Select>
                </FormControl>
            </Stack>
        </>
    );
};

type PlacePickerProps = {
    label: string,
    value: FlashDestination | null,
    onChange: (destination: FlashDestination) => void
}

const PlacePicker = (props: PlacePickerProps) => {
    const isUserTO = useSelector((state: AppState) => state.user.user?.client_full?.type !== 2);
    const [options, setOptions] = useState<FlashDestination[]>([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const cancelToken = useRef<CancelTokenSource | null>(null);
    const fireNetworkRequest = useCallback(
        debounce(
            async (search: string, isUserTO: boolean) => {
                try {
                    setLoading(true);
                    cancelToken.current?.cancel('Request cancelled.');
                    cancelToken.current = axios.CancelToken.source();
                    const response = await makeDestinationsQuickSearch({
                        search,
                        isUserTO,
                        cancelToken: cancelToken.current
                    });
                    setOptions(response.data);
                } catch (error: any) {
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            },
            500
        ),
        []
    );

    useEffect(() => {
        if (search.trim().length > 0) {
            fireNetworkRequest(search, isUserTO);
        } else {
            setOptions([]);
            setLoading(false);
        }
    }, [search, isUserTO]);

    return (
        <Autocomplete
            value={props.value}
            loading={loading}
            options={options}
            isOptionEqualToValue={(a, b) => a.destination_id === b.destination_id}
            filterOptions={(options) => options}
            getOptionLabel={(option) => isString(option) ? option : option.international_name}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.label}
                />
            )}
            renderOption={(props, option) => (
                <li {...props}>
                    <LocationOn sx={{ marginRight: 1 }} />
                    {option.international_name}
                </li>
            )}
            componentsProps={{
                popper: {
                    sx: {
                        minWidth: 230
                    }
                }
            }}
            sx={{ width: '100%' }}
            onChange={(_, value) => value && !isString(value) && props.onChange(value)}
            onInputChange={(_, value) => isString(value) && setSearch(value)}
            fullWidth
            freeSolo
        />
    );
};

type DestinationsRequestOptions = {
    isUserTO: boolean,
    search: string,
    cancelToken: CancelTokenSource
}

function makeDestinationsQuickSearch(options: DestinationsRequestOptions): Promise<AxiosResponse<FlashDestination[]>> {
    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        return axios.get(
            `${API_HREF}client/${window.id_owner}/destinations/flash_search/`,
            {
                headers,
                cancelToken: options.cancelToken.token,
                params: {
                    limit: 30,
                    ordering: 'current_version__type',
                    search: options.search,
                    reduced: true,
                    visibility__in: options.isUserTO ?
                        'PUBLIC,PRIVATE_TO' :
                        'PUBLIC'
                }
            }
        );
    }

    throw new Error('Please login.');
}
